import { isSSRBuild } from '~/utils/ssr/build-type';

export const MAIN_PAGE_NAME = 'main-page';
export const BOOKING_URL = `${isSSRBuild ? '' : '/'}booking`;
export const BOOKING_NAME = 'booking';
export const BOOKING_URL_HASH_SEARCH =
  `${BOOKING_URL}/:step/:departureStationIata?/:arrivalStationIata?/:departureDate?/:returnDate?` +
  '/:adult?/:child?/:infant?/:rescueFareCode?/:outboundDepartureTime?/:outboundBundle?/:returnDepartureTime?/:returnBundle?';
export const CONNECTED_FLIGHTS_NAME = 'mix-and-match';
export const CONNECTED_BOOKING_URL_HASH_SEARCH = 'booking/mix-and-match';
