import log from 'loglevel';
import { unrefElement } from '@vueuse/core';
import isString from '~/utils/object/is-string';
import { isServer } from '~/utils/ssr';

function easeInOutSine(t, b, c, d) {
  return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b;
}

/**
 * Inject script tag into header
 * @param url
 */
export function addScript(url) {
  const script = document.createElement('script');
  script.src = url;
  document.head.append(script);
}

/**
 * Get coordinates, works with absolute/relative positioned elements too
 * @param el
 * @return {{top: number, left: number}}
 */
export function getCoords(el, scrollOnElement) {
  // crossbrowser version
  const box = el.getBoundingClientRect();
  const body = document.body;
  const docEl = document.documentElement;
  const scrollTop = scrollOnElement
    ? scrollOnElement.scrollTop
    : window.pageYOffset || docEl.scrollTop || body.scrollTop || 0;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft || 0;
  const clientTop =
    (scrollOnElement && scrollOnElement.clientTop) ||
    docEl.clientTop ||
    body.clientTop ||
    0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;
  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;
  return {
    top: Math.round(top),
    left: Math.round(left),
  };
}

// convenience method for getting the height of multiple elements
// (by html element or querySelector)
export const getHeight = (...els) =>
  els.reduce((sum, el) => {
    sum += Number.parseInt(
      getComputedStyle(typeof el === 'string' ? document.querySelector(el) : el).height,
      10
    );
    return sum;
  }, 0);

// so far checks only vertically, but that should be enough for us
export function isInViewport(el, grace = 0) {
  const rect = el.getBoundingClientRect();
  return rect.top + el.scrollHeight + grace >= 0;
}

// width + margins
export function getOuterWidth(elem) {
  const style = getComputedStyle(elem);
  return (
    elem.offsetWidth +
    Number.parseFloat(style.marginRight || 0) +
    Number.parseFloat(style.marginLeft || 0)
  );
}

/**
 * @param to  mixed     <- this can be an HTML element OR a number (pixels) or css selector
 * @param callback      on finish function
 * @param duration      duration in msec
 * @param element       HTMLElement  optional    element to operate on, see usage below in `scrollModalToTop`
 * @param gap           target coordinate modifier
 *
 * alternate usage:
 * `scrollTo({ to: '#foo-bar', gap: -50 });`
 */
export function scrollTo(to, callback, duration = 500, element, gap = 0) {
  const MARKER_CLASS = 'auto-scrolling';

  // result of a wrong selector
  if (to === null) {
    return;
  }

  // it works with one param only, because mindreading
  if (typeof to === 'object' && to.to && arguments.length === 1) {
    callback = to.callback;
    duration = to.duration || duration;
    gap = to.gap || gap;
    element = to.scrollOnElement || null;
    to = to.to;
  }

  // a string that looks kinda like a selector
  if (isString(to)) {
    const selector = to;
    to = document.querySelector(selector);
    if (!to) {
      log.warn(`scrollTo: "${selector}" element id not found.`);
      return;
    }
  }

  let cancelOnWheel = false;

  const scrollHandler = () => {
    cancelOnWheel = true;
    window.removeEventListener('wheel', scrollHandler);
    window.removeEventListener('drag', scrollHandler, false);
    window.removeEventListener('touchstart', scrollHandler, false);
  };

  window.addEventListener('wheel', scrollHandler);
  window.addEventListener('drag', scrollHandler, false);
  window.addEventListener('touchstart', scrollHandler, false);

  if (to instanceof HTMLElement || to instanceof SVGElement) {
    to = getCoords(to, element).top;
  }

  if (gap) {
    to += gap;
  }

  const requestAnimFrame = (() => {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (cb) {
        setTimeout(cb, 1000 / 60);
      }
    );
  })();

  function move(amount) {
    if (element) {
      element.scrollTop = amount;
      return;
    }
    document.documentElement.scrollTop = amount;
    document.body.parentNode.scrollTop = amount;
    document.body.scrollTop = amount;
  }

  function position() {
    if (element) {
      return element.scrollTop;
    }
    return (
      document.documentElement.scrollTop ||
      document.body.parentNode.scrollTop ||
      document.body.scrollTop
    );
  }

  const start = position();
  const change = to - start;
  let currentTime = 0;
  const increment = 20;

  // false or zero defaults to 40
  if (!duration) {
    duration = 40;
  }

  const animateScroll = () => {
    currentTime += increment;

    const value = easeInOutSine(currentTime, start, change, duration);
    move(value);

    if (currentTime < duration && !cancelOnWheel) {
      requestAnimFrame(animateScroll);
    } else {
      document.body.classList.remove(MARKER_CLASS);
      if (typeof callback === 'function') {
        callback();
      }
    }
  };

  document.body.classList.add(MARKER_CLASS);
  animateScroll();
}

/**
 * Scroll a modal's contents to top (just a shorthand for readability)
 * @param elem    html element, optional
 * @param offset
 */
export function scrollModalToTop(elem, offset = 0, duration = undefined) {
  // try to autodetect a single visible modal,
  // this of course will NOT work modalception
  if (!elem) {
    const modals = Array.from(document.querySelectorAll('.modal-popup'));
    elem = modals.find((el) => el.style.display !== 'none'); // may be undefined, but that's okay
  }
  scrollTo(offset, undefined, duration, elem);
}

/**
 * Element index (compared ot siblings)
 * @param elem
 * @return {number}
 */
export function index(elem) {
  const children = Array.from(elem.parentNode.childNodes).filter(
    (childNode) => childNode.nodeType === 1
  );
  return children.indexOf(elem);
}

/**
 *
 * @param target         DOM element to focus on
 * @param container      overflowed dom element
 * @param idx            number or not set (in that case it tries to determine the index by itself)
 * @param items          DOM element list or selector string
 */
export function centerElement(target, container, idx, items) {
  if (idx === -1) {
    log.warn('centerElement invalid index');
    return;
  }
  const rtlModifier = window.isRtl ? -1 : 1;
  const containerWidth = container.offsetWidth;
  const cs = getComputedStyle(target);
  const targetWidth =
    target.offsetWidth +
    Number.parseFloat(cs.marginRight) +
    Number.parseFloat(cs.marginLeft);

  // we are not using the idx calculator here, but if you want to do that, please fix it for rtl
  items = typeof items === 'string' ? container.querySelectorAll(items) : items;
  idx = typeof idx !== 'undefined' ? idx : index(target);

  // The total number of pixels between the target's left edge and the left edge of it's container
  let preceding = 0;
  if (window.isRtl) {
    for (let i = items.length - 1; i > items.length - idx - 1; i--) {
      preceding += getOuterWidth(items[i]);
    }
  } else {
    for (let i = 0; i < idx; i++) {
      preceding += getOuterWidth(items[i]);
    }
  }

  // The total number of pixels we need to show to the left of the target to center it.
  const offset = (containerWidth - targetWidth) / 2;
  container.scrollLeft = Math.max(0, preceding - offset) * rtlModifier;
}

// please enhance it to your needs
function propParser(propName, s) {
  if (s === '0') {
    s = 0;
  }
  if (s.endsWith('px') && !/\s/.test(s)) {
    // 10px, but not "10px 0"
    s = Number.parseInt(s.replace(/px$/, ''), 10); // "300.562px" => 300
  }
  return s;
}

/**
 * Get inlne or computed style for element, tries to return numbers if possible
 *
 * @param el
 * @param prop
 */
export function getStyle(el, prop) {
  if (!el) {
    return;
  }
  const style = el.style[prop]; // always a string, may be empty
  const computedStyle = (window.getComputedStyle(el, null) || {})[prop]; // may be undefined
  return propParser(prop, style || computedStyle);
}

// set style from an object. empty string or false resets, null is ignored
// nothing special, you probably will be fine using direct style setters
// instead of this
export const setStyle = (el, propsObj, defaultUnit = 'px') => {
  if (typeof el === 'string') {
    el = document.querySelector(el);
  }
  Object.keys(propsObj).forEach((key) => {
    let val = propsObj[key];
    if (val === false) {
      val = '';
    }
    val = typeof val === 'number' ? `${val}${defaultUnit}` : val;
    if (val !== null) {
      el.style[key] = val;
    }
  });
};

/**
 * Lock scrolling on the body, useful if you have an overflow auto container
 * maximized over the viewport (but for some reason you are not using the modal component)
 * @param state  boolean
 */
export function lockBodyOverflow(state) {
  // console.log('lockbodyOverflow');
  // do NOT use inline style, others are messing with that
  // (modals and app.js are the main culprits here)
  document.body.classList[state ? 'add' : 'remove']('no-scroll');
}

export function fixAndLockBodyState(state) {
  // do NOT use inline style, others are messing with that
  // (modals and app.js are the main culprits here)
  // console.log('fixandLockBodyState');
  document.body.classList[state ? 'add' : 'remove']('body--fixed');
}

/**
 * Escape html string
 * @param s
 * @returns string
 */
export function escapeHtml(s) {
  const buffer = escapeHtml.buffer;
  buffer.textNode.textContent = s;
  return buffer.div.innerHTML;
}

// static helper for escapeHtml
(function (host) {
  if (isServer()) return;

  host.buffer = {
    textNode: document.createTextNode(''),
    div: document.createElement('div'),
    test: document.createElement('div'),
  };
  host.buffer.div.append(host.buffer.textNode);
})(escapeHtml);

/**
 * Detect the browser transition event handler
 * @returns string
 */
export function whichTransitionEvent() {
  let t;
  const el = document.createElement('fakeelement');
  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  for (t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
}

/**
 * Detect the video currently playing or not
 * @returns boolean
 */
export function isVideoPlaying(video) {
  return video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
}

export const isObjectFitSupported = () => {
  if (isServer()) return false;

  return Boolean('objectFit' in document.documentElement.style);
};

export const hasTouchEvents = () =>
  'ontouchstart' in window || window.TouchEvent || window.DocumentTouch;

export function sortByDocumentPosition(fn, nodes) {
  return nodes.slice().sort((aItem, zItem) => {
    const a = fn(aItem);
    const z = fn(zItem);

    if (a === null || z === null) return 0;

    const position = a.compareDocumentPosition(z);

    if (position === Node.DOCUMENT_POSITION_FOLLOWING) return -1;
    if (position === Node.DOCUMENT_POSITION_PRECEDING) return 1;
    return 0;
  });
}

export function getOwnerDocument(element) {
  if (element instanceof Node) return element.ownerDocument;
  if (element?.value) {
    const domElement = unrefElement(element);
    if (domElement) return domElement.ownerDocument;
  }

  return document;
}

export function appendTo(element, targetSelector = 'body') {
  if (!element || isServer()) return;
  const target = document.querySelector(targetSelector);
  target?.append(element);
}

export function waitForElementToExist(selector, callback) {
  const waitInterval = setInterval(() => {
    if (document.querySelector(selector)) {
      clearInterval(waitInterval);
      callback(document.querySelector(selector));
    }
  }, 100);

  const clearWaitInterval = () => clearInterval(waitInterval);

  return clearWaitInterval;
}
