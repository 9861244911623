export const SET_CVV = 'booking/setCvv';
export const SET_WDC_IS_ADDED = 'booking/setWdcIsAdded';
export const UNSET_WDC_IS_ADDED = 'booking/unsetWdcIsAdded';
export const SET_WDC_REQUIRED_MEMBERSHIP = 'booking/setWdcRequiredMembership';
export const SET_NEW_BOOKING = 'booking/setNewBooking';
export const RESET_BOOKING = 'booking/reset';
export const SET_KAYAK_CLICK_ID = 'booking/kayakClickId';
export const SET_WEGO_CLICK_ID = 'booking/wegoClickId';
export const CHANGE_BANK_TRANSFER_ACCOUNT = 'booking/changeBankTransferAccount';
export const SET_SAME_FOR_BOTH_FLIGHTS_CHECKED = 'booking/setSameForBothFlightsChecked';
export const MARK_WDC_RENEWAL_REQUEST = 'booking/markWdcRenewalRequest';
export const SET_CARRIER_CODES = 'booking/setCarrierCodes';
export const RESET_FLIGHT_CARRIERS_CONDITIONS_ACCEPTED =
  'booking/resetFlightCarriersConditionsAccepted';
export const SET_FARE_LOCK_FINALIZE_DATA = 'booking/setFareLockFinalizeData';
export const SET_NEWSLETTER_SUBSCRIPTION = 'booking/setNewsletterSubscription';
export const SET_NEWSLETTER_SUBSCRIPTION_LOCATION =
  'booking/setNewsletterSubscriptionLocation';
export const SET_WDC_AVAILABLE_MEMBERSHIPS = 'booking/setWdcAvailableMemberships';
export const SET_IS_LOGGED_IN_AFTER_SELECT_FLAG = 'booking/setIsLoggedInAfterSelectFlag';
export const SET_FLIGHT = 'booking/setFlight';
export const SET_FARES = 'booking/setFares';
export const SET_GROUP_SEAT_REQUEST = 'booking/setGroupSeatRequest';
export const SET_PASSENGER_NUMBERS_AND_COUNTS = 'booking/setPassengerNumbersAndCounts';
export const SET_INDEPENDENT_PASSENGERS = 'booking/setIndependentPassenger';
export const SET_IS_DOMESTIC = 'booking/setIsDomestic';
export const SET_IS_WDC_PREMIUM_ENABLED = 'booking/setIsWdcPremiumEnabled';
export const UPDATE_WDC = 'booking/updateWdc';
export const SET_IS_WDC_STEP_AVAILABLE = 'booking/setIsWdcStepAvailable';
export const UNSET_IS_WDC_STEP_AVAILABLE = 'booking/unsetIsWdcStepAvailable';
export const SET_IS_WDC_UPGRADED_FROM_SIDEBAR = 'booking/setIsWdcUpgradedFromSidebar';

export const HANDLE_PAYMENT_OPTIONS = 'booking/handlePaymentOptions';
export const HANDLE_PAYMENT_SELECTED_OPTION = 'booking/handlePaymentSelectedOption';
export const SET_PAYMENT_IS_CORPORATE_CARD_FEE_ACCEPTED =
  'booking/setPaymentIsCorporateCardFeeAccepted';
export const SET_PAYMENT_CORPORATE_CARD_FEE_INFO =
  'booking/setPaymentCorporateCardFeeInfo';
export const SET_PAYMENT_CORPORATE_CARD_FEE_PERCENT =
  'booking/setPaymentCorporateCardFeePercent';
export const HANDLE_PAYMENT_ADD_STORED_CREDIT_CARD =
  'booking/handlePaymentAddStoredCreditCard';
export const HANDLE_PAYMENT_ADD_RESIDUAL_AMOUNT =
  'booking/handlePaymentAddResidualAmount';
export const HANDLE_PAYMENT_REMOVE_CONFIDENTIAL_CARD_DATA =
  'booking/handlePaymentRemoveConfidentialCardData';

export const HANDLE_PAYMENT_REMOVE_CREDIT_CARD_DATA =
  'booking/handlePaymentRemoveCreditCardData';

export const HANDLE_PAYMENT_DISABLE_PARTIAL_PAYMENT =
  'booking/handlePaymentDisablePartialPayment';
export const GET_CONTACT_DETAILS = 'booking/getContactDetails';
export const SET_ALREADY_PAID_STATUS = 'booking/setAlreadyPaidStatus';
export const SET_RESTART_BOOKING_MODAL_VISIBILITY =
  'booking/setRestartBookingModalVisibility';

// legacy mutation types
export const RESET_OUTBOUND_FLIGHT = 'booking/resetOutboundFlight';
export const RESET_RETURN_FLIGHT = 'booking/resetReturnFlight';

// Airport transfer
export const SET_AIRPORT_TRANSFER_MODAL_VISIBILITY =
  'booking/isAirportTransferModalVisible';
export const SET_AIRPORT_TRANSFER_MODAL_DIRECTION =
  'booking/airportTransferModalDirection';

// Insurance

export const SET_ACORD_SERVICE_INSURANCE_ACCEPTED = 'booking/insurancePolicyAccepted';
export const SET_ACORD_SERVICE_INFO_MODAL_TRACKED =
  'booking/setAcordServiceInfoModalTracked';
export const SET_ACORD_SERVICE_INSURANCE_NONE_SELECTED = 'booking/insuranceNoneSelected';
export const SET_ACORD_SERVICE_INSURANCE_FORCED = 'booking/insuranceForced';

export const SET_HAS_SELECTED_CAR = 'booking/setHasSelectedCar';
export const UNSET_HAS_SELECTED_CAR = 'booking/unsetHasSelectedCar';

export const SET_BUNDLE_UPSELL_ORIGINAL_COMMON_BUNDLE =
  'booking/setBundleUpsellOriginalCommonBundle';

export const TOGGLE_CANCELLATION_INSURANCE = 'booking/toggleCancellationInsurance';
export const SET_CANCELLATION_INSURANCE = 'booking/setCancellationInsurance';

export const TOGGLE_TRAVEL_INSURANCE = 'booking/toggleTravelInsurance';
export const SET_TRAVEL_INSURANCE = 'booking/setTravelInsurance';

export const TOGGLE_BAGGAGE_INSURANCE = 'booking/toggleBaggageInsurance';
export const SET_BAGGAGE_INSURANCE = 'booking/setBaggageInsurance';

export const SHOW_URGENCY_MODAL = 'booking/showUrgencyModal';
export const HIDE_URGENCY_MODAL = 'booking/hideUrgencyModal';

export const SET_URGENCY_MODAL_ALREADY_SHOWED = 'booking/urgencyModalAlreadyShowed';

export const SET_WDC_SAVING_MODAL_VISIBILITY = 'booking/setWdcSavingModalVisibility';

export const SET_BOOKING_FLOW_STEP = 'booking/setBookingFlowStep';

export const SET_WDC_STEP_NECESSARY = 'booking/setWdcStepNecessary';

export const SET_BILLING_VALID = 'booking/setIsBillingValid';

export const SHOW_SINGLE_SERVICE_MODAL = 'booking/showSingleServiceModal';
export const HIDE_SINGLE_SERVICE_MODAL = 'booking/hideSingleServiceModal';

export const SET_WIZZ_FLEX_MODAL_VISIBILITY = 'booking/setWizzFlexModalVisibility';
export const SET_PREVIOUS_BOOKING_DETAILS = 'booking/setPreviousBookingDetails';
export const SET_IS_PAYMENT_EXIT_INTENT_MODAL_SEEN =
  'booking/setIsPaymentExitIntentModalSeen';
export const SET_IS_FLIGHT_SEARCH_IN_SIDEBAR_VISIBLE =
  'booking/setIsFlightSearchInSidebarVisible';
export const UNSET_IS_FLIGHT_SEARCH_IN_SIDEBAR_VISIBLE =
  'booking/unsetIsFlightSearchInSidebarVisible';
export const SET_IS_FLIGHT_SEARCH_MODAL_VISIBLE = 'booking/setIsFlightSearchModalVisible';
export const UNSET_IS_FLIGHT_SEARCH_MODAL_VISIBLE =
  'booking/unsetIsFlightSearchModalVisible';
export const SET_DESTINATION_BASED_NOTIFICATION_ACCEPTED =
  'booking/setDestinationBasedNotificationAccepted';

export const RESET_CAPTCHA_RESPONSE = 'booking/resetCaptchaResponse';

export const SET_PAYMENT_CURRENCY = 'booking/setPaymentCurrency';

export const SET_GROUP_PARTIAL_PAYMENT_VALUES = 'booking/setGroupPartialPaymentValues';

export const SET_SELECTED_IDEAL_ISSUER = 'booking/setSelectedIdealIssuer';

export const SET_IS_REGISTERED_FOR_NEWSLETTER = 'booking/setIsRegisteredForNewsletter';

export const SET_IS_CAPTCHA_REQUIRED = 'booking/setIsCaptchaRequired';

export const SET_CHANGE_FLIGHT_DISCLAIMER_TYPE = 'booking/setChangeFlightDisclaimerType';

export const SET_IS_PREMIUM_SEAT_LOADING = 'booking/setIsPremiumSeatRefundLoading';

export const SET_PREMIUM_SEAT_REFUND = 'booking/setPremiumSeatRefund';

export const SET_PREMIUM_SEAT_REFUND_ERRORS = 'booking/setPremiumSeatRefundErrors';

export const SET_PASSENGERS_STEP_PENDING = 'booking/setPassengersStepPending';
export const SET_SELECTED_FLIGHT_PENDING = 'booking/setSelectedFlightPending';
export const SET_PAYMENT_STEP_PENDING = 'booking/setPaymentStepPending';

export const SET_FLIGHT_CARRIER_ACCEPTED = 'booking/setFlightCarrierAccepted';

export const SET_CURRENCY_CHANGE_WARNING_DISMISSED =
  'booking/setCurrencyChangeWarningDismissed';

export const SET_CHECK_IN_PARAMS = 'booking/setCheckInParams';
export const SET_CHECK_IN_PARAMS_ERRORS = 'booking/setCheckInParamsErrors';
