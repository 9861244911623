import axios from 'axios';
import { CUSTOMER_TYPE } from '~/constants';
import {
  CLAIM_CHECK_RECORD_LOCATOR,
  CLAIM_CLAIM,
  CLAIM_MYCLAIMS,
  CLAIM_CLAIM_LANGUAGES,
  CLAIM_VALIDATE_CLAIMABILITY,
  CUSTOMER_AGENCY_REGISTRATION,
  CUSTOMER_AUTOLOGIN,
  CUSTOMER_CHANGEPASSWORD,
  CUSTOMER_CONSENT,
  CUSTOMER_CUSTOMERACCOUNTHISTORY,
  CUSTOMER_DATAINQUIRY,
  CUSTOMER_EMAIL_VALIDATION,
  CUSTOMER_FEEDBACK,
  CUSTOMER_FORGOT_PASSWORD,
  CUSTOMER_LOGIN,
  CUSTOMER_LOGOUT,
  CUSTOMER_MYBOOKINGS,
  CUSTOMER_NEWSLETTER,
  CUSTOMER_PROFILE,
  CUSTOMER_FREE_WDC,
  CUSTOMER_REGISTRATION,
  CUSTOMER_STORED_CREDIT_CARD,
  CUSTOMER_UPDATEMOBILEPHONE,
  CUSTOMER_VALIDATE_FORGOT_PASSWORD,
  CUSTOMER_VERIFY_CAPTCHA,
  CUSTOMER_VOUCHERHISTORY,
  CUSTOMER_YOUR_NEXT_FLIGHT,
  CUSTOMER_YOUR_NEXT_CHANGED_FLIGHT,
  CUSTOMER_ADD_UPCOMING_FLIGHT,
  CUSTOMER_REMOVE_UPCOMING_FLIGHT,
  USER_SESSION_NEW,
  CLAIM_CLAIMDETAILS,
  CLAIM_SUPPLEMENT_CLAIM,
  CUSTOMER_EMAIL_VERIFICATION,
  CUSTOMER_EMAIL_VERIFICATION_RESEND_EMAIL,
  CUSTOMER_GET_CUSTOMER_PRICE_ALERTS,
  CUSTOMER_ADD_CUSTOMER_PRICE_ALERT,
  CUSTOMER_DELETE_CUSTOMER_PRICE_ALERT,
} from '~/constants/api-endpoint';

/**
 * @type {(responseToken: string) => Promise}
 */
export const verifyCaptcha = (responseToken) =>
  axios.post(CUSTOMER_VERIFY_CAPTCHA, { captchaResponse: responseToken });

export const forgotPassword = (data) => axios.post(CUSTOMER_FORGOT_PASSWORD, data);

export const validateForgotPassword = (data) =>
  axios.post(CUSTOMER_VALIDATE_FORGOT_PASSWORD, data);

export const validateEmail = (data) => axios.post(CUSTOMER_EMAIL_VALIDATION, data);

export const verifyEmail = (data) => axios.post(CUSTOMER_EMAIL_VERIFICATION, data);

export const resendEmailVerificationEmail = (data) =>
  axios.post(CUSTOMER_EMAIL_VERIFICATION_RESEND_EMAIL, data);

export const register = (user) => axios.post(CUSTOMER_REGISTRATION, user);

export const agencyRegistration = (agency) =>
  axios.post(CUSTOMER_AGENCY_REGISTRATION, agency);

export const autologin = (guid) => axios.get(CUSTOMER_AUTOLOGIN, { params: { guid } });

export const login = (credentials) => axios.post(CUSTOMER_LOGIN, credentials);

export const logout = () =>
  axios.post(CUSTOMER_LOGOUT).catch(() => axios.get(USER_SESSION_NEW));

export const getDataInquiryDetails = () => axios.get(CUSTOMER_DATAINQUIRY);

export const submitDataInquiryRequest = (payload) =>
  axios.post(CUSTOMER_DATAINQUIRY, payload);

export const getProfile = () =>
  axios.get(CUSTOMER_PROFILE).then((response) => {
    // may only happen with manually created agency accounts;
    // the isPrivate prop is a helper/computed for (!!companyName)
    if (response.data.customerType === CUSTOMER_TYPE.AGENCY) {
      response.data.isPrivate = false;
    }
    return response;
  });

export const getCreditCards = () => axios.get(CUSTOMER_STORED_CREDIT_CARD);

export const setCreditCardAsDefault = (data) =>
  axios.post(CUSTOMER_STORED_CREDIT_CARD, data);

export const deleteCreditCard = (data) =>
  axios({
    url: CUSTOMER_STORED_CREDIT_CARD,
    method: 'delete',
    data,
  });

export const setProfile = (data) => axios.post(CUSTOMER_PROFILE, data);

export const setFreeWDC = (data) => axios.post(CUSTOMER_FREE_WDC, data);

export const updateMobilePhone = (data) => axios.post(CUSTOMER_UPDATEMOBILEPHONE, data);

export const changePassword = (data) => axios.post(CUSTOMER_CHANGEPASSWORD, data);

export const getVoucherHistory = () => axios.get(CUSTOMER_VOUCHERHISTORY);

export const getMyBookings = (data) => axios.post(CUSTOMER_MYBOOKINGS, data);

export const getAccountHistory = () => axios.get(CUSTOMER_CUSTOMERACCOUNTHISTORY);

export const getNextFlight = () => axios.post(CUSTOMER_YOUR_NEXT_FLIGHT);

export const getNextChangedFlight = () => axios.post(CUSTOMER_YOUR_NEXT_CHANGED_FLIGHT);

export const subscribeToNewsletter = (data) => axios.post(CUSTOMER_NEWSLETTER, data);

export const unsubscribeFromNewsletter = (data) =>
  axios({
    url: CUSTOMER_NEWSLETTER,
    method: 'delete',
    data,
  });

export const getClaims = () => axios.get(CLAIM_CLAIM);

export const getClaimLanguages = () => axios.get(CLAIM_CLAIM_LANGUAGES);

export const getClaimsFlightRecord = (data) =>
  axios.post(CLAIM_CHECK_RECORD_LOCATOR, data);

export const validateClaimability = (data) =>
  axios.post(CLAIM_VALIDATE_CLAIMABILITY, data);

export const postClaims = (data) => axios.post(CLAIM_CLAIM, data);

export const myClaims = () => axios.get(CLAIM_MYCLAIMS);

export const getClaimDetails = (identifier, paymentId) =>
  axios.get(CLAIM_CLAIMDETAILS, { params: { identifier, paymentId } });

export const postSupplementClaim = (data) => axios.post(CLAIM_SUPPLEMENT_CLAIM, data);

export const acceptNewPrivacyPolicy = () =>
  axios.post(CUSTOMER_CONSENT, { ppAccepted: true });

export const newsletterConsent = (consentAccepted, email) =>
  axios.post(CUSTOMER_CONSENT, { consentAccepted, email });

export const getCustomerFeedback = (stage) =>
  axios.get(CUSTOMER_FEEDBACK, { params: { stage } });

export const sendCustomerFeedback = (data) => axios.post(CUSTOMER_FEEDBACK, data);

export const addUpcomingFlight = (data) => axios.post(CUSTOMER_ADD_UPCOMING_FLIGHT, data);

export const removeUpcomingFlight = (data) =>
  axios.post(CUSTOMER_REMOVE_UPCOMING_FLIGHT, data);

export const getCustomerPriceAlerts = () => axios.get(CUSTOMER_GET_CUSTOMER_PRICE_ALERTS);

export const addCustomerPriceAlert = (data) =>
  axios.post(CUSTOMER_ADD_CUSTOMER_PRICE_ALERT, data);

export const deleteCustomerPriceAlert = (priceAlertId) =>
  axios.post(CUSTOMER_DELETE_CUSTOMER_PRICE_ALERT, { id: priceAlertId });
