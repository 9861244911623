import to from 'await-to-js';
import { OUTBOUND_FLIGHT_NAME, STATION_ARRIVAL } from '~/constants';
import isEmpty from '~/utils/object/is-empty';
import isNotNil from '~/utils/object/is-not-nil';
import { apiErrorsOrDefault } from '~/utils/services/errors';
import { captureException } from '~/utils/logging';
import { removeSessionItem } from '~/utils/storage';
import { createAction } from '~/utils/store';
import { getFlightDates } from '~/services/search';
import {
  addMonths,
  currentDateAndTime,
  toDefaultFormat,
  isAfter,
  isBefore,
  isDayBefore,
} from '~/utils/date';
import compose from '~/utils/fp/compose';
import * as resourcesGetters from '../resources/getters';
import * as getters from './getters';
import * as m from './mutation-types';

export const reset = createAction(m.RESET);

/**
 * @type {(store: Store) => void}
 */
export const initialize = (store) => {
  const { commit, state } = store;
  commit(m.INITIALIZE, resourcesGetters.stationsWithFakes(state));

  const { iata: departureIata } = getters.departureStation(state);
  const { iata: arrivalIata } = getters.arrivalStation(state);

  // notes: on language change we have to refresh the stations with the translated ones
  if (isNotNil(departureIata)) setDepartureStation(store, { iata: departureIata });
  if (isNotNil(arrivalIata)) setArrivalStation(store, { iata: arrivalIata });
};

/**
 * @type {(store: Store, value: Price) => void}
 */
export const setBookingCurrencyRenewalPrice = createAction(
  m.SET_BOOKING_CURRENCY_RENEWAL_PRICE
);

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const initWdc = createAction(m.INIT_WDC);

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const setWdcFlag = createAction(m.SET_WDC_FLAG);

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const setBundles = createAction(m.SET_BUNDLES);

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const setFlights = createAction(m.SET_FLIGHTS);

/**
 * @type {(store: Store, payload: Object<string, any>) => void}
 */
export const setFees = createAction(m.SET_FEES);

/**
 * @type {(store: Store) => void}
 */
export const setWdc = createAction(m.SET_WDC);

/**
 * @type {(store: Store) => void}
 */
export const unsetWdc = createAction(m.UNSET_WDC);

export const resetSearch = ({ commit }) => {
  commit(m.RESET_SEARCH);
  removeSessionItem('search');
};

export const getPossibleDatesForFlight = async (store, payload = {}) => {
  const { commit } = store;
  const { departureStation, arrivalStation, selectedDate, direction } = payload;
  const from =
    direction !== OUTBOUND_FLIGHT_NAME
      ? toDefaultFormat(selectedDate)
      : direction === OUTBOUND_FLIGHT_NAME && isBefore(selectedDate, currentDateAndTime())
      ? toDefaultFormat(currentDateAndTime())
      : toDefaultFormat(selectedDate);

  const until = compose(toDefaultFormat, addMonths(2))(from);
  const response = await getFlightDates(departureStation, arrivalStation, from, until);

  if (isEmpty(response.data)) {
    commit(m.RESET_POSSIBLE_DATES_FOR_FLIGHT, direction);
    return;
  }

  commit(m.UPDATE_POSSIBLE_DATES_FOR_FLIGHT, {
    value: response.data.flightDates,
    direction,
  });
};

export const fetchFlightDates = async (store, payload = {}) => {
  const { state, commit } = store;
  let {
    departureDate = toDefaultFormat(getters.searchMinDate(state)),
    returnDate = toDefaultFormat(getters.searchMaxDate(state)),
    isUpdate = false,
    isReturn = false,
  } = payload;

  departureDate = isBefore(departureDate, currentDateAndTime())
    ? toDefaultFormat(currentDateAndTime())
    : departureDate;

  const mutation = isUpdate ? m.UPDATE_FLIGHT_DATES : m.SET_FLIGHT_DATES;
  const departureStationIata = isReturn
    ? getters.arrivalStationIata(state)
    : getters.departureStationIata(state);
  const arrivalStationIata = isReturn
    ? getters.departureStationIata(state)
    : getters.arrivalStationIata(state);

  commit(m.SET_FLIGHT_DATES_SEARCH_ERRORS, []);

  const [error, response] = await to(
    getFlightDates(departureStationIata, arrivalStationIata, departureDate, returnDate)
  );

  if (error) {
    captureException(error);
    commit(m.SET_FLIGHT_DATES_SEARCH_ERRORS, apiErrorsOrDefault(error));
  }
  commit(mutation, response.data.flightDates);
};

export const setDepartureStation = (store, { iata, accessibility }) => {
  const { commit, state } = store;
  const stations = resourcesGetters.stationsWithFakes(state);
  commit(m.SET_DEPARTURE_STATION, { stations, iata, accessibility });
};

export const setArrivalStation = (store, { iata, accessibility }) => {
  const { commit, state } = store;
  const stations = resourcesGetters.stationsWithFakes(state);
  commit(m.SET_ARRIVAL_STATION, { stations, iata, accessibility });
};

export const swapStations = (store) => {
  const { commit, state } = store;
  const stations = resourcesGetters.stationsWithFakes(state);

  const [arrivalStation, departureStation] = [
    getters.departureStation(state),
    getters.arrivalStation(state),
  ];

  commit(m.SET_DEPARTURE_STATION, { stations, iata: departureStation.iata });
  commit(m.SET_ARRIVAL_STATION, { stations, iata: arrivalStation.iata });
};

export const setDestination = (store, { iata, accessibility }) => {
  const { commit, state } = store;
  const stations = resourcesGetters.stationsWithFakes(state);
  commit(m.SET_DESTINATION, { stations, iata, accessibility });
};

export const setBookingDestination = createAction(m.SET_BOOKING_DESTINATION);

export const setCheckinDate = createAction(m.SET_CHECKIN_DATE);

export const setCheckoutDate = createAction(m.SET_CHECKOUT_DATE);

export const setDepartureDate = createAction(m.SET_DEPARTURE_DATE);

export const setReturnDate = createAction(m.SET_RETURN_DATE);

export const setPassengers = createAction(m.SET_PASSENGERS);

export const setNumberOfAdultPassengers = createAction(m.SET_NUMBER_OF_ADULT_PASSENGERS);

export const setNumberOfChildrenPassengers = createAction(
  m.SET_NUMBER_OF_CHILDREN_PASSENGERS
);

export const setNumberOfInfantPassengers = createAction(
  m.SET_NUMBER_OF_INFANT_PASSENGERS
);

export const setFocusedField = createAction(m.SET_FOCUSED_FIELD);

export const setStationSearchTerm = createAction(m.SET_STATION_SEARCH_TERM);

export const setOriginalDepartureDate = createAction(m.SET_ORIGINAL_DEPARTURE_DATE);

export const setSearchWdc = createAction(m.SET_SEARCH_WDC);

// todo refactor
export const setSearchDate = (store, date, shouldChangeFocusedField) => {
  const { state } = store;
  const isOneWayRoute = getters.isOneWayRoute(state);
  const formattedReturnDate = getters.formattedReturnDate(state);
  const formattedDepartureDate = getters.formattedDepartureDate(state);

  if (getters.isDepartureDateFocused(state)) {
    setDepartureDate(store, date);
    if (isAfter(date, formattedReturnDate)) {
      setReturnDate(
        store,
        getters
          .flightDates(state)
          .find((currentDate) => isDayBefore(date, currentDate)) || date
      );
    }
    if (shouldChangeFocusedField)
      setFocusedField(store, isOneWayRoute ? '' : 'returnDate');
  } else if (!isOneWayRoute) {
    if (isBefore(date, formattedDepartureDate)) {
      setDepartureDate(store, date);
    } else {
      setReturnDate(store, date);
      if (shouldChangeFocusedField) setFocusedField(store, '');
    }
  }
};

export const updateStation = (store, { iata }) => {
  const { commit, state } = store;

  if (!iata) {
    setFocusedField(store, '');
    setStationSearchTerm(store, '');
    commit(m.SET_FLIGHT_DATES_SEARCH_ERRORS, ['DepartureStationIsNull']);
    return;
  }

  if (getters.isDepartureStationFocused(state)) {
    setDepartureStation(store, { iata });
    setFocusedField(store, STATION_ARRIVAL);
    setArrivalStation(store, { iata: '' });
    setStationSearchTerm(store, '');
    return;
  }

  setArrivalStation(store, { iata });
  setFocusedField(store, '');
  setStationSearchTerm(store, '');
  fetchFlightDates(store);
};

export const setSavedSearchParams = createAction(m.SET_SAVED_SEARCH_PARAMS);

export const reinstateSavedSearchParams = createAction(m.REINSTATE_SAVED_SEARCH_PARAMS);

export const resetSearchSuggestions = createAction(m.RESET_SEARCH_SUGGESTIONS);

export const resetDepartureStation = createAction(m.RESET_DEPARTURE_STATION);

export const resetArrivalStation = createAction(m.RESET_ARRIVAL_STATION);

export const setRescueFareCode = createAction(m.SET_RESCUE_FARE_CODE);

export const setIsBundleCompositionWarningTextVisible = createAction(
  m.SET_IS_BUNDLE_COMPOSITION_WARNING_TEXT_VISIBLE
);

export const setIsDomestic = createAction(m.SET_IS_DOMESTIC);

/**
 * @type {(store: Store, value: string[]) => void}
 */
export const setDepartureStationAlternatives = createAction(
  m.SET_DEPARTURE_STATION_ALTERNATIVES
);

/**
 * @type {(store: Store, value: string[]) => void}
 */
export const setArrivalStationAlternatives = createAction(
  m.SET_ARRIVAL_STATION_ALTERNATIVES
);

export const setDepartureStationSuggestion = createAction(
  m.SET_DEPARTURE_STATION_SUGGESTION
);

export const setArrivalStationSuggestion = createAction(m.SET_ARRIVAL_STATION_SUGGESTION);

export const setIsTimetable = createAction(m.SET_IS_TIMETABLE);
export const setActiveFilter = createAction(m.SET_ACTIVE_FILTER);
