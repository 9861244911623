export const RESET = 'search/reset';
export const RESET_SEARCH = 'search/resetSearch';
export const INITIALIZE = 'search/initialize';
export const UPDATE_POSSIBLE_DATES_FOR_FLIGHT = 'search/updatePossibleDatesForFlight';
export const RESET_POSSIBLE_DATES_FOR_FLIGHT = 'search/updateResetPossibleDatesForFlight';
export const INIT_WDC = 'search/initWdc';
export const SET_WDC = 'search/setWdc';
export const UNSET_WDC = 'search/unsetWdc';
export const SET_WDC_FLAG = 'search/setWdcFlag';
export const SET_BOOKING_DESTINATION = 'search/setBookingDestination';
export const SET_DESTINATION = 'search/setDestination';
export const SET_CHECKIN_DATE = 'search/setCheckinDate';
export const SET_CHECKOUT_DATE = 'search/setCheckoutDate';
export const SET_DEPARTURE_DATE = 'search/setDepartureDate';
export const SET_RETURN_DATE = 'search/setReturnDate';
export const SET_FEES = 'search/setFees';
export const SET_IS_BUNDLE_COMPOSITION_WARNING_TEXT_VISIBLE =
  'search/setIsBundleCompositionWarningTextVisible';
export const SET_IS_DOMESTIC = 'search/setIsDomestic';
export const SET_BOOKING_CURRENCY_RENEWAL_PRICE = 'search/setBookingCurrencyRenewalPrice';
export const SET_FLIGHTS = 'search/setFlights';
export const SET_BUNDLES = 'search/setBundles';
export const SET_SAVED_SEARCH_PARAMS = 'search/setSavedSearchParams';
export const REINSTATE_SAVED_SEARCH_PARAMS = 'search/reinstateSavedSearchParams';
export const SET_DEPARTURE_STATION = 'search/setDepartureStation';
export const SET_ARRIVAL_STATION = 'search/setArrivalStation';

export const SET_FOCUSED_FIELD = 'search/setFocusedField';
export const SET_STATION_SEARCH_TERM = 'search/setStationSearchTerm';
export const SET_FLIGHT_DATES = 'search/setFlightDates';
export const SET_PASSENGERS = 'search/setPassengers';
export const SET_ORIGINAL_DEPARTURE_DATE = 'search/setOriginalDepartureDate';
export const UPDATE_FLIGHT_DATES = 'search/updateFlightDates';
export const SET_FLIGHT_DATES_SEARCH_ERRORS = 'search/setFlightDateSearchErrors';
export const RESET_SEARCH_SUGGESTIONS = 'search/resetSearchSuggestions';
export const RESET_DEPARTURE_STATION = 'search/resetDepartureStation';
export const RESET_ARRIVAL_STATION = 'search/resetArrivalStation';
export const SET_RESCUE_FARE_CODE = 'search/setRescueFareCode';
export const SET_DEPARTURE_STATION_ALTERNATIVES =
  'search/setDepartureStationAlternatives';
export const SET_ARRIVAL_STATION_ALTERNATIVES = 'search/setArrivalStationAlternatives';

export const SET_NUMBER_OF_ADULT_PASSENGERS = 'search/setNumberOfAdultPassengers';
export const SET_NUMBER_OF_CHILDREN_PASSENGERS = 'search/setNumberOfChildrenPassengers';
export const SET_NUMBER_OF_INFANT_PASSENGERS = 'search/setNumberOfInfantPassengers';

export const SET_DEPARTURE_STATION_SUGGESTION = 'search/setDepartureStationSuggestion';
export const SET_ARRIVAL_STATION_SUGGESTION = 'search/setArrivalStationSuggestion';

export const SET_SEARCH_WDC = 'search/setSearchWdc';

export const SET_IS_TIMETABLE = 'search/setIsTimetable';
export const SET_ACTIVE_FILTER = 'search/setActiveFilter';
