import to from 'await-to-js';
import log from 'loglevel';
import { gtm } from '~/utils/analytics/gtm';
import {
  FLIGHT_CANCELLATION_PATH,
  FLIGHT_CANCELLATION_STEP_PATH_INFORMATION,
} from '~/router/flight-cancellation/constants';
import { updateBundleAncillaryServices } from '~/utils/booking/bundle';
import { captureAndLogException, captureException } from '~/utils/logging';
import { getBundles } from '~/services/asset';
import * as BookingService from '~/services/booking';
import * as CheckInService from '~/services/check-in';
import * as PaymentService from '~/services/payment';
import * as EcommerceService from '~/services/ecommerce';
import * as AncillaryService from '~/services/booking/ancillary';
import * as PromotionService from '~/services/promotion';
import {
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
  ITINERARY_MODAL_MAKE_CHANGES,
  ITINERARY_MODAL_MODIFY_BOOKING_FLEXIBLE_TRAVEL_PARTNER,
  ITINERARY_MODAL_NAME_CHANGE,
  ITINERARY_SECTION_BOOKING_DETAILS,
  ITINERARY_SECTION_PASSENGER_DETAILS,
  ITINERARY_SECTION_ADD_BAGS,
  BOTH_FLIGHT_NAME,
  ITINERARY_MODAL_PASSENGER_INFORMATION,
  ITINERARY_MODAL_CHECK_IN,
  ITINERARY_MODAL_CONFIRM,
  ITINERARY_MODAL_SERVICES_CONFIRM,
  ITINERARY_MODAL_AIRPORT_TRANSFER,
  ITINERARY_MODAL_ACORD_SERVICE,
  BOARDING_CARD_FORMAT_HTML,
  BOARDING_CARD_FORMAT_PDF,
  FALLBACK_LOCALE_CODE,
  FLOW_TYPE_ADD_SERVICES,
  ITINERARY_MODAL_CONTACT_UPDATE,
  ITINERARY_MODAL_FLIGHT_CHANGE_WARNING,
  OPERATION_REBOOK_FLIGHT,
  OPERATION_ACCEPT_NEW_FLIGHT,
  OPERATION_REBOOK_REFUND,
  BUNDLE_MIDDLE,
  ITINERARY_MODAL_AIRPORT_PARKING,
} from '~/constants';
import { ANALYTICS_CHECKOUT_STEP_ITINERARY } from '~/constants/analytics';
import * as BookingUtils from '~/utils/booking/booking';
import { copyPrivilegePassParams } from '~/utils/booking/passenger';
import { emit } from '~/utils/event-bus';

import isNil from '~/utils/object/is-nil';
import isNotEmpty from '~/utils/object/is-not-empty';
import isNotNil from '~/utils/object/is-not-nil';
import { apiErrors, apiErrorsOr, apiErrorsOrDefault } from '~/utils/services/errors';
import { createAction } from '~/utils/store';
import {
  goToFlightChange,
  goToPayment,
  goToCheckInFlow,
  goToAddTravelDocuments,
  getUrl,
  goToItinerary,
} from '~/utils/navigation';
import { scrollTo } from '~/utils/dom';
import { trackFloodlightActivity } from '~/utils/conversion-tracking/double-click';
import { isMobileScreen, setHref } from '~/utils/browser';
import { sendGaEvent, trackPhoneNumber } from '~/utils/analytics';
import propEqFalse from '~/utils/fp/prop-eq-false';
import both from '~/utils/fp/both';
import pathOr from '~/utils/fp/path-or';
import { INITIAL_FLOW_SEAT } from '~/components/itinerary-common/constants';
import { ANALYTICS_FLOW_TYPE_CHECKIN } from '~/services/ecommerce/constants';
import { DISRUPTION_TYPE_CARRIER_CHANGE } from '~/constants/flight-disruption';
import { openInNewTab, saveAsFile } from '~/services/boarding-card';
import * as analyticsGetters from '../analytics/getters';
import * as coreBookingActions from '../core-booking/actions';
import * as coreBookingGetters from '../core-booking/getters';
import * as localeActions from '../locale/actions';
import * as resourcesGetters from '../resources/getters';
import * as flightDisruptionActions from '../flight-disruption/actions';
import * as promotionActions from '../promotion/actions';
import * as systemActions from '../system/actions';
import * as userGetters from '../user/getters';
import * as userActions from '../user/actions';
import * as summaryGetters from '../summary/getters';
import * as summaryActions from '../summary/actions';
import * as flightChangeActions from '../flight-change/actions';
import * as bookingGetters from '../booking/getters';
import * as bookingActions from '../booking/actions';
import * as passengersGetters from '../passengers/getters';
import * as passengersActions from '../passengers/actions';
import * as ancillariesActions from '../ancillaries/actions';
import * as upsellActions from '../upsell/actions';
import { isHybridMode } from '../global-value/getters';
import * as getters from './getters';
import {
  formatBannedPassengers,
  formatValidationPassengers,
  DIVIDE_KIND_CANCELLATION,
  DIVIDE_KIND_FLIGHT_CHANGE,
  isGlobalOperationAvailable,
} from './internal';
import { query } from './query';
import * as m from './mutation-types';
import {
  WDC_CUT_STATUS_FAILED,
  WDC_CUT_STATUS_SUCCESS,
  HTTP_ERROR_MESSAGES,
} from './constants';

const DIVIDE_STATUS_CHECK_POLL_INTERVAL = 30000;
const WDC_CUT_STATUS_CHECK_POLL_INTERVAL = 30000;

let itineraryFetchPromise = null;
let divideStatusPollingIntervalId = null;
let wdcCutStatusPollingIntervalId = null;

export const reset = createAction(m.RESET);

export const resetUpsoldBookings = createAction(m.RESET_UPSOLD_BOOKINGS);

/**
 * @type {(store: Store, pnr: string) => void}
 */
export const addPnrToUpsoldBookings = createAction(m.ADD_PNR_TO_UPSOLD_BOOKINGS);

export const setIsAddServiceVisitedOnce = createAction(m.SET_IS_ADD_SERVICE_VISITED_ONCE);

export const unsetIsAddServiceVisitedOnce = createAction(
  m.UNSET_IS_ADD_SERVICE_VISITED_ONCE
);

export const setFlightsOnItinerary = createAction(m.SET_FLIGHTS_ON_ITINERARY);

export const partiallyResetItinerary = ({ commit }) =>
  commit(m.PARTIALLY_RESET_ITINERARY);

export const updateItineraryBooking = ({ commit }, booking) =>
  commit(m.UPDATE_ITINERARY_BOOKING, booking);

export const resetItinerary = (store) => {
  partiallyResetItinerary(store);
  hideActiveModal(store);
};

const startDivideStatusCheckPolling = (store) => {
  if (isNotNil(divideStatusPollingIntervalId)) return;
  divideStatusPollingIntervalId = setInterval(
    () => checkDivideStatus(store),
    DIVIDE_STATUS_CHECK_POLL_INTERVAL
  );
};

const checkDivideStatus = async (store) => {
  const { commit, state } = store;
  commit(m.INCREMENT_ITINERARY_LOADING_COUNTER);
  const _isCancellationPending = getters.isCancellationPending(state);

  // notes: according to the be the only possible error scenario is the 'sessionMismatch'
  const [, response] = await to(BookingService.checkDivideStatus());
  commit(m.DECREMENT_ITINERARY_LOADING_COUNTER);

  if (!response.data.divideInProgress) {
    clearInterval(divideStatusPollingIntervalId);

    await loadItinerary(store, {
      pnr: getters.pnr(state),
      lastName: getters.lastName(state),
    });
    commit(
      m.SET_DIVIDE_KIND,
      _isCancellationPending ? DIVIDE_KIND_CANCELLATION : DIVIDE_KIND_FLIGHT_CHANGE
    );
  }
};

const startWdcCutStatusCheckPolling = (store) => {
  if (isNotNil(wdcCutStatusPollingIntervalId)) return;
  wdcCutStatusPollingIntervalId = setInterval(
    () => checkWdcCutStatus(store),
    WDC_CUT_STATUS_CHECK_POLL_INTERVAL
  );
};

const checkWdcCutStatus = async (store) => {
  const { commit, state } = store;

  commit(m.INCREMENT_ITINERARY_LOADING_COUNTER);
  const [, response] = await to(BookingService.checkWdcCutStatus());
  commit(m.DECREMENT_ITINERARY_LOADING_COUNTER);

  const { status, pnr } = response.data;

  if (status === WDC_CUT_STATUS_SUCCESS && isNotNil(pnr)) {
    clearInterval(wdcCutStatusPollingIntervalId);
    goToItinerary(pnr, getters.lastName(state));
  } else if (status === WDC_CUT_STATUS_FAILED) {
    // no decision from business what to do here so until then we do nothing.
    clearInterval(wdcCutStatusPollingIntervalId);
  }
};

const getFlightDisruptionInitPayload = (store, payload) => {
  const { state } = store;
  const disruptionInformation = payload.disruptionInformation || {};
  const gccAcceptanceInformation = payload.gccAcceptanceInformation || {};
  const reSeatingInformation = disruptionInformation.reSeatingInformation || {};
  const isCarrierChange =
    disruptionInformation.disruptionType === 'none' &&
    gccAcceptanceInformation.hasToAcceptGCC;

  const disruptionType = isCarrierChange
    ? DISRUPTION_TYPE_CARRIER_CHANGE
    : disruptionInformation.disruptionType ?? null;

  flightDisruptionActions.initialize(store, {
    ...disruptionInformation,
    disruptionType,
    canRebook: isGlobalOperationAvailable(OPERATION_REBOOK_FLIGHT, state),
    canAcceptNewFlight: isGlobalOperationAvailable(OPERATION_ACCEPT_NEW_FLIGHT, state),
    canRefund: isGlobalOperationAvailable(OPERATION_REBOOK_REFUND, state),
    passengers: getters.passengers(state),
    shouldAcceptCarrierChange: gccAcceptanceInformation.hasToAcceptGCC ?? false,
    acceptanceCarrierCode: gccAcceptanceInformation.acceptanceCarrierCode,
    outboundChangedPassengers: reSeatingInformation.outboundFlightSeatChanges ?? [],
    returnChangedPassengers: reSeatingInformation.returnFlightSeatChanges ?? [],
    refundPrice: reSeatingInformation.wizzRefundPrice ?? null,
  });
};

export const loadItinerary = async (
  store,
  { pnr, lastName, keepBooking = false, firstName = null } = {}
) => {
  const { commit, state } = store;

  const isNewPnr = bookingGetters.isNewBooking(state);
  const isWdcRecentlyBought = bookingGetters.isWdcAdded(state);
  clearServerErrors(store);

  if (!keepBooking) {
    ancillariesActions.reset(store);
    bookingActions.resetBooking(store);
    summaryActions.resetSummary(store);
    resetItinerary(store);
  }

  commit(m.INCREMENT_ITINERARY_LOADING_COUNTER);
  commit(m.SET_IS_FLIGHT_CHANGE_WARNING_MODAL_SHOWN, false);

  if (!itineraryFetchPromise) {
    itineraryFetchPromise = BookingService.itinerary({
      pnr,
      lastName,
      ...(isNotEmpty(firstName) && { firstName }),
      keepBooking,
    });
  }

  flightDisruptionActions.resetFlightDisruption(store);

  const [error, response] = await to(itineraryFetchPromise);
  itineraryFetchPromise = null;

  commit(m.DECREMENT_ITINERARY_LOADING_COUNTER);

  if (error) {
    if ([400, 404, 500].includes(error.status)) commit(m.ADD_SERVER_ERROR, error.status);
    const defaultError = HTTP_ERROR_MESSAGES.get(error.status);
    commit(m.ADD_ITINERARY_ERRORS, apiErrorsOr(defaultError, error));
    commit(m.UPDATE_ITINERARY_BOOKING, { pnr, lastName });
    return;
  }
  updateItineraryBooking(store, { ...response.data, lastName });
  setCurrentCurrency(store, getters.bookingCurrencyCode(state));
  killTheCryingMushroom(store);

  if (!getters.hasReaccomodateError(state))
    getFlightDisruptionInitPayload(store, response.data);

  if (getters.isGoogleAnalyticsPostRequired(state)) trackPurchases(store);

  if (isNewPnr) {
    commit(m.SET_IS_BOOKING_DOT_COM_MODAL_VISIBLE);
    // TODO: refactor and create a new prop in itinerary store, use that instead of booking store
    bookingActions.setNewBooking(store, isNewPnr);
  }

  if (isNewPnr && isWdcRecentlyBought) userActions.updateWdcAndFetchProfile(store);

  if (getters.isCancellationPending(state) || getters.isFlightChangePending(state)) {
    startDivideStatusCheckPolling(store);
  }

  if (getters.isWdcCutPending(state)) {
    startWdcCutStatusCheckPolling(store);
  }
};

export const fetchCheckInPassengers = async (
  store,
  direction = BOTH_FLIGHT_NAME,
  isForced = false
) => {
  // isForced: in standalone check-in flow if a user comes from a bookmark
  // we need to display every information we got on them
  const { state, commit } = store;
  const shouldGetOutboundInformation = direction !== RETURN_FLIGHT_NAME;
  const shouldGetReturnInformation = direction !== OUTBOUND_FLIGHT_NAME;
  if (!getters.isBookingAvailable(state)) {
    return;
  }

  commit(m.INCREMENT_ITINERARY_LOADING_COUNTER);

  if (
    ((!getters.isOutboundFlightFlown(state) && getters.isConfirmed(state)) || isForced) &&
    shouldGetOutboundInformation
  ) {
    await fetchCheckInPassengersForFlightDirection(store, OUTBOUND_FLIGHT_NAME);
  }

  if (
    getters.isRoundTrip(state) &&
    ((!getters.isReturnFlightFlown(state) && getters.isConfirmed(state)) || isForced) &&
    shouldGetReturnInformation
  ) {
    await fetchCheckInPassengersForFlightDirection(store, RETURN_FLIGHT_NAME);
  }

  commit(m.DECREMENT_ITINERARY_LOADING_COUNTER);
};

const fetchCheckInPassengersForFlightDirection = async (store, direction) => {
  const { commit } = store;
  const isReturnFlight = direction !== OUTBOUND_FLIGHT_NAME;

  commit(m.INCREMENT_ITINERARY_LOADING_COUNTER);
  const [error, response] = await to(CheckInService.getMultipleCheckInV2(isReturnFlight));
  commit(m.DECREMENT_ITINERARY_LOADING_COUNTER);

  if (error) {
    captureException(error);
    commit(m.ADD_ITINERARY_ERRORS, apiErrors(error));
    return;
  }

  setCheckInDocumentRulesByCountries(store, response.checkInDocumentRulesByCountries);

  commit(m.UPDATE_ITINERARY_CHECK_IN_PASSENGERS, {
    direction: !isReturnFlight ? OUTBOUND_FLIGHT_NAME : RETURN_FLIGHT_NAME,
    checkInPassengers: response.passengers,
  });

  if (isReturnFlight) {
    commit(m.SET_RETURN_FREE_CHECKIN_START_DATE, response.returnFreeCheckinStartDate);
  } else {
    commit(m.SET_OUTBOUND_FREE_CHECKIN_START_DATE, response.outboundFreeCheckinStartDate);
  }
};

export const setCheckInDocumentRulesByCountries = createAction(
  m.SET_CHECK_IN_DOCUMENT_RULES_BY_COUNTRIES
);

// todo: confusing name, it does a lot else than fetching
/**
 * @type {(store: Store, payload: { shouldFetch3rdPartyAncillaries: boolean, withSummary: boolean }) => void}
 */
export const fetchPassengersAndAncillaries = async (store, payload = {}) => {
  const { state, commit } = store;
  const { shouldFetch3rdPartyAncillaries = true, withSummary = false } = payload;
  commit(m.INCREMENT_ITINERARY_LOADING_COUNTER);
  const [passengerError] = await to(passengersActions.getPassengers(store));
  if (shouldFetch3rdPartyAncillaries) await AncillaryService.initializeInsurance();

  const [ancillaryError] = await to(
    ancillariesActions.getAncillaries(store, {
      withSummary,
      forcePerPassengerPrice: true,
    })
  );
  // Airport parking is dependent on the ancillaries response,
  // do not merge this with the previous if condition
  if (shouldFetch3rdPartyAncillaries) await ancillariesActions.getAirportParking(store);
  await promotionActions.getPromotion(store);

  commit(m.DECREMENT_ITINERARY_LOADING_COUNTER);

  if (passengerError) {
    captureException(passengerError);
    commit(m.ADD_ITINERARY_ERRORS, apiErrors(passengerError));
  } else {
    const passengers = passengersGetters.passengers(state);
    const itineraryPassengers = getters.passengers(state);
    copyPrivilegePassParams(passengers, itineraryPassengers);
  }

  if (ancillaryError) {
    captureException(ancillaryError);
    commit(m.ADD_ITINERARY_ERRORS, apiErrors(ancillaryError));
  }

  // TODO: make it less painful and automated (calculate instead of set a variable)
  coreBookingActions.setFlowType(
    store,
    BookingUtils.isCheckIn() || coreBookingGetters.isCheckInFlow(state)
      ? ANALYTICS_FLOW_TYPE_CHECKIN
      : FLOW_TYPE_ADD_SERVICES
  );
};

export const _postBookingUpgrade = async (store) => {
  const { commit } = store;

  commit(m.INCREMENT_ITINERARY_LOADING_COUNTER);
  const [error] = await to(postBookingUpgrade());
  commit(m.DECREMENT_ITINERARY_LOADING_COUNTER);
  if (error) {
    return commit(m.ADD_ITINERARY_ERRORS, apiErrors(error));
  }
  commit(m.SET_IS_UPGRADE_BUNDLE_MODAL_VISIBLE);
};

const postBookingUpgrade = () =>
  AncillaryService.postBookingUpgrade({ bundle: BUNDLE_MIDDLE });

export const openSeatSelectionModal = ({ state, commit }, direction) => {
  const _ = query(
    [
      'bookingCurrencyCode:currencyCode',
      'rawPassengers:passengers',
      'outboundFlight',
      'returnFlight',
      'checkInStatuses',
    ],
    state
  );

  const { outboundFlight, returnFlight } = _;
  const selectedFlightName = direction;

  const selectedPassengerId = (
    _.passengers.find(
      both(propEqFalse('reducedMobility'), propEqFalse('reducedMobilityAssistant'))
    ) || _.passengers[0]
  ).id;

  emit('seatSelectionModal_show', {
    ..._,
    outboundFlight,
    returnFlight,
    selectedFlightName,
    selectedPassengerId,
    hideContinueButton: true,
    isAlignedToTop: summaryGetters.hasItemsInCart(state),
  });

  pseudoShowSeatSelectionModal({ state, commit });
};

export const closeSeatSelectionModal = (store) => {
  emit('seatSelectionModal_close', {
    onClosedCallback: () => pseudoHideSeatSelectionModal(store),
  });
};

export const setPreferredInvoiceTab = ({ commit }, value) =>
  commit(m.SET_PREFERRED_INVOICE_TAB, value);

export const setActiveModal = createAction(m.SET_ITINERARY_ACTIVE_MODAL);

export const setCurrentCurrency = createAction(m.SET_CURRENT_CURRENCY);

export const hideActiveModal = (store) => setActiveModal(store, null);

export const showPassengerInformationModal = (store) =>
  setActiveModal(store, ITINERARY_MODAL_PASSENGER_INFORMATION);

export const hidePassengerInformationModal = (store) => {
  if (getters.activeModal(store.state) === ITINERARY_MODAL_PASSENGER_INFORMATION)
    hideActiveModal(store);
};

export const showCheckedInPassengersWarningModal = (store) =>
  setActiveModal(store, ITINERARY_MODAL_FLIGHT_CHANGE_WARNING);

export const showCheckInModal = (store) =>
  setActiveModal(store, ITINERARY_MODAL_CHECK_IN);

export const showConfirmModal = (store) => setActiveModal(store, ITINERARY_MODAL_CONFIRM);

export const showServicesConfirmModal = (store) =>
  setActiveModal(store, ITINERARY_MODAL_SERVICES_CONFIRM);

export const hideCheckInModal = (store) => {
  if (getters.activeModal(store.state) === ITINERARY_MODAL_CHECK_IN)
    hideActiveModal(store);
};

export const showMakeChangesModal = (store) => {
  sendGaEvent({
    category: 'Itinerary',
    action: 'Make changes – Itinerary',
  });

  setActiveModal(store, ITINERARY_MODAL_MAKE_CHANGES);
};

export const showContactUpdateModal = (store) => {
  setActiveModal(store, ITINERARY_MODAL_CONTACT_UPDATE);
  store.commit(m.RESET_CONTACT_UPDATE_STATUS);
};

export const showNameChangeModal = (store) =>
  setActiveModal(store, ITINERARY_MODAL_NAME_CHANGE);

export const showModifyBookingFlexibleTravelPartnersModal = (store) =>
  setActiveModal(store, ITINERARY_MODAL_MODIFY_BOOKING_FLEXIBLE_TRAVEL_PARTNER);

export const hideModifyBookingFlexibleTravelPartnersModal = (store) => {
  if (
    getters.activeModal(store.state) ===
    ITINERARY_MODAL_MODIFY_BOOKING_FLEXIBLE_TRAVEL_PARTNER
  ) {
    hideActiveModal(store);
  }
};

export const showAirportTransferModal = (store) =>
  setActiveModal(store, ITINERARY_MODAL_AIRPORT_TRANSFER);

export const hideAirportTransferModal = (store) => {
  if (getters.activeModal(store.state) === ITINERARY_MODAL_AIRPORT_TRANSFER)
    hideActiveModal(store);
};

export const showAirportParkingModal = (store) =>
  setActiveModal(store, ITINERARY_MODAL_AIRPORT_PARKING);

export const hideAirportParkingModal = (store) => {
  if (getters.activeModal(store.state) === ITINERARY_MODAL_AIRPORT_PARKING)
    hideActiveModal(store);
};

export const showAcordServiceModal = (store) =>
  setActiveModal(store, ITINERARY_MODAL_ACORD_SERVICE);

export const hideAcordServiceModal = (store) => {
  if (getters.activeModal(store.state) === ITINERARY_MODAL_ACORD_SERVICE)
    hideActiveModal(store);
};

export const pseudoShowSeatSelectionModal = ({ commit }) => {
  // wood-cutting vol.1.
  commit(m.UPDATE_SEAT_SELECTION_MODAL_VISIBILITY, true);
};

export const pseudoHideSeatSelectionModal = ({ commit }) => {
  // wood-cutting vol.2.
  commit(m.UPDATE_SEAT_SELECTION_MODAL_VISIBILITY, false);
};

export const setActiveSection = createAction(m.SET_ITINERARY_ACTIVE_SECTION);

export const startInitialFlow = (store, flowData) => {
  if (isNil(flowData)) return;

  switch (flowData.name) {
    case INITIAL_FLOW_SEAT:
      startSeatChangeFlow(store, flowData.direction);
      break;
    default:
      startStandardFlow(store);
  }
};

export const startCheckInFlow = (store, data) => {
  const { pnr, lastName, direction } = data;
  goToCheckInFlow(pnr, lastName, direction);
};

export const startTravelDocumentsFlow = (
  { state, commit },
  { pnr, lastName, direction }
) => {
  goToAddTravelDocuments(pnr, lastName, direction);
};

export const startStandardFlow = (store) =>
  setActiveSection(store, ITINERARY_SECTION_BOOKING_DETAILS);

export const startPassengerDetailsChangeFlow = (store) => {
  setActiveSection(store, ITINERARY_SECTION_PASSENGER_DETAILS);
  scrollTo(0, 0);
};

export const startSeatChangeFlow = (store, direction) => {
  setActiveSection(store, ITINERARY_SECTION_BOOKING_DETAILS);
  openSeatSelectionModal(store, direction);
};

export const startAddBagsFlow = (store) => {
  setActiveSection(store, ITINERARY_SECTION_ADD_BAGS);
  scrollTo(0, 0);
};

export const startNameChangeFlow = (store, passenger) => {
  const { commit } = store;
  commit(m.UPDATE_ITINERARY_SELECTED_PASSENGER, passenger);
  showNameChangeModal(store);
};

export const startFlightCancellationFlow = (store) => {
  if (getters.hasTravelPartners(store.state)) {
    showModifyBookingFlexibleTravelPartnersModal(store);
  } else {
    setHref(
      `${getUrl(
        'modifyBooking'
      )}/${FLIGHT_CANCELLATION_PATH}/${FLIGHT_CANCELLATION_STEP_PATH_INFORMATION}`
    );
  }
};

// set currency codes for booking flow 😭🔪🍄
// if you read this line, please refactor the currency handling ASAP!
export const killTheCryingMushroom = async (store) => {
  const { state } = store;

  const MCP_SELECTION_SOURCE = 'MCP on new itinerary';
  const [error] = await to(
    summaryActions.changePaymentCurrency(store, {
      currencyCode: getters.currentCurrency(state),
      source: MCP_SELECTION_SOURCE,
    })
  );

  if (error) {
    captureAndLogException('Unable to set currency code for payment', error);
  }

  coreBookingActions.setCurrencyCodes(store, {
    bookingCurrencyCode: getters.bookingCurrencyCode(state),
    departureCurrencyCode: getters.departureStationCurrencyCode(state),
    arrivalCurrencyCode: getters.arrivalStationCurrencyCode(state),
  });
};

export const postSubmit = async (store) => {
  const { commit, state } = store;
  if (summaryGetters.hasAmountDue(state) || getters.hasTotalAmountDue(state)) {
    commit(m.INCREMENT_ITINERARY_LOADING_COUNTER);
    killTheCryingMushroom(store);

    goToPayment(isHybridMode(state));
  } else {
    commit(m.INCREMENT_ITINERARY_LOADING_COUNTER);
    const [error] = await to(PaymentService.empty());
    commit(m.DECREMENT_ITINERARY_LOADING_COUNTER);

    if (error) {
      log.error('error occurred while payment depenishing');
      commit(m.ADD_ITINERARY_ERRORS, apiErrors(error));
      return;
    }

    window.location.reload(true);
  }
};

export const changeCurrency = async (store, currencyCode) => {
  const [error] = await to(
    summaryActions.changePaymentCurrency(store, { currencyCode, isSilent: true })
  );
  if (error) return captureAndLogException(error);
  setCurrentCurrency(store, currencyCode);
};

export const showLoginModal = (store, props = {}) => {
  store.commit(m.UNSET_LOGIN_REQUIRED_TO_CONTINUE_TO_PAYMENT);
  return systemActions.showLoginModal(store, { openingSource: 'Itinerary', ...props });
};

export const showLogInModalOnCheckIn = (store) =>
  showLoginModalWithLoginRequiredToContinueWithPayment(store, {
    openingSource: 'CheckIn',
  });

export const showLoginModalWithLoginRequiredToContinueWithPayment = (
  store,
  props = {}
) => {
  store.commit(m.SET_LOGIN_REQUIRED_TO_CONTINUE_TO_PAYMENT);
  return systemActions.showLoginModal(store, {
    openingSource: 'Itinerary',
    ...props,
    modalTitle: 'itinerary-common-login-modal-login-to-continue-payment',
    cancelButtonLabel: 'itinerary-common-login-modal-cancel-and-discard-changes',
  });
};

export const loadItineraryFetchPassengersAncillariesCheckInPassengers = async (store) => {
  await loadItinerary(store, {
    pnr: getters.pnr(store.state),
    lastName: getters.lastName(store.state),
  });
  await fetchPassengersAndAncillaries(store);
  return fetchCheckInPassengers(store);
};

export const trackPurchases = async (store) => {
  const { state } = store;
  const isCheckIn = coreBookingGetters.isCheckInFlow(state);

  if (getters.isEmptyPaymentOccurred(state)) return;

  const [analyticsErrors, analyticsResponse] = await to(BookingService.getAnalytics());
  if (analyticsErrors) {
    captureAndLogException('Unable to get analytics data for itinerary', analyticsErrors);
    return;
  }

  let analyticalData = analyticsResponse?.data?.googleAnalyticalData;
  const transaction = analyticsResponse?.data?.googleAnalyticalData?.transaction;

  if (!analyticalData || !transaction) {
    log.error('Unable to get analytics data for itinerary');
    return;
  }

  if (!isCheckIn) {
    trackFloodlightActivity(ANALYTICS_CHECKOUT_STEP_ITINERARY);
  }

  if (isCheckIn) {
    analyticalData.products.forEach((product) => {
      product.flowType = ANALYTICS_FLOW_TYPE_CHECKIN;
    });
  }

  analyticalData = {
    ...analyticalData,
    purchaseLocations: analyticsGetters.purchaseLocations(state),
    hasSavingOnWdc: analyticsGetters.wdcDiscountSaving(state),
    paymentMethod: analyticsGetters.selectedPaymentOptionForAnalytics(state),
  };

  const eventLabel = isCheckIn ? 'Check in' : 'Itinerary';
  EcommerceService.measureTransaction(analyticalData, eventLabel);
  EcommerceService.measureGa4Transaction(analyticalData, eventLabel);

  BookingService.analytics();
};

export const downloadBoardingCard = async (
  store,
  type,
  passengerNumber,
  isInfant,
  isReturnFlight
) => {
  const gaType = type === 'passbook' ? 'Mobile' : type.toUpperCase();
  sendGaEvent({
    category: 'Boarding card open',
    action: 'Click',
    label: gaType,
    nonInteractive: true,
  });

  const boardingCardFormat = {
    html: BOARDING_CARD_FORMAT_HTML,
    pdf: BOARDING_CARD_FORMAT_PDF,
  }[type];
  const languageCode =
    window.language && window.language.culture
      ? window.language.culture
      : FALLBACK_LOCALE_CODE;
  const url = `?passengerNumber=${passengerNumber}
    &isReturnFlight=${isReturnFlight}
    &isInfant=${isInfant}
    &boardingCardFormat=${boardingCardFormat}
    &languageCode=${languageCode}`.replace(/\s+/g, '');

  const [error, response] = await to(
    CheckInService.getBoardingCard(url, { responseType: 'arraybuffer' })
  );

  if (error) {
    captureAndLogException(
      `Unable to get ${type} boarding card for passenger (nr. ${passengerNumber})`,
      error
    );
    throw new Error(
      `Unable to get ${type} boarding card for passenger (nr. ${passengerNumber})`
    );
  }

  const contentType = response.headers['content-type'];

  if (isMobileScreen()) {
    openInNewTab(response.data, contentType);
  } else {
    const contentDisposition = response.headers['content-disposition'];
    const name = contentDisposition ? contentDisposition.split('=')[1] : 'BoardingCard';

    saveAsFile(response.data, contentType, name);
  }

  // "reinitialize" AS flow
  fetchPassengersAndAncillaries(store);
};

export const addCheckInPassenger = ({ commit }, passengerNumber, direction) => {
  commit(m.ADD_CHECK_IN_PASSENGER, { passengerNumber, direction });
};

export const removeCheckInPassenger = ({ commit }, passengerNumber) => {
  commit(m.REMOVE_CHECK_IN_PASSENGER, passengerNumber);
};

export const updateCheckInPassengerProp = ({ commit }, data) => {
  commit(m.UPDATE_CHECK_IN_PASSENGER_PROP, data);
};

export const clearSelectedCheckInPassengers = ({ commit }) => {
  commit(m.RESET_SELECTED_CHECK_IN_PASSENGERS);
};

export const setEmptyPaymentOccurredStatus = ({ commit }, value) => {
  commit(m.SET_EMPTY_PAYMENT_OCCURRED_STATUS, value);
};

export const saveRecentlyPurchasedItems = ({ commit, state }) => {
  commit(m.SET_RECENTLY_PURCHASED_ITEMS, {
    services: summaryGetters.groupedServices(state),
    ancillaries: summaryGetters.passengersAncillaries(state),
    seats: summaryGetters.seatsPassengers(state),
  });
};

export const saveRecentlyPaidAmount = createAction(m.SET_RECENTLY_PAID_AMOUNT);

export const clearErrors = createAction(m.REMOVE_ITINERARY_ERRORS);

export const clearServerErrors = createAction(m.REMOVE_SERVER_ERRORS);

export const fetchPriceDetails = async (store) => {
  const { commit, state } = store;
  const [error, priceDetails] = await to(
    BookingService.getPriceDetails(resourcesGetters.stationsWithFakes(state))
  );

  if (error) {
    captureException(error);
    commit(m.ADD_PRICE_DETAILS_ERRORS, apiErrors(error));
    return;
  }

  commit(m.UPDATE_PRICE_DETAILS, priceDetails);
};

export const setIsSeatSelectionNeeded = createAction(m.SET_IS_SEAT_SELECTION_NEEDED);

export const resetPriceDetailsErrors = createAction(m.RESET_PRICE_DETAILS_ERRORS);

export const hideBookingDotComModal = createAction(
  m.UNSET_IS_BOOKING_DOT_COM_MODAL_VISIBLE
);

export const setBannedPassengerSeen = createAction(m.SET_BANNED_PASSENGER_SEEN);

export const updateContactPhoneNumber = async ({ commit }, payload) => {
  commit(m.REMOVE_CONTACT_ERRORS);
  commit(m.INCREMENT_CONTACT_LOADING_COUNTER);
  const [error] = await to(BookingService.changeContactPhoneNumber(payload));
  commit(m.DECREMENT_CONTACT_LOADING_COUNTER);

  if (error) {
    commit(m.ADD_CONTACT_ERRORS, apiErrors(error));
    return;
  }

  trackPhoneNumber('update', 'click', 'itineraryEditModal');

  commit(m.UPDATE_CONTACT_PHONE_NUMBER, payload.mobilePhone);
};

export const resetAvailableFeedbackStages = createAction(
  m.RESET_AVAILABLE_FEEDBACK_STAGES
);

export const startRebookFlow = async (store) => {
  store.commit(m.INCREMENT_ITINERARY_LOADING_COUNTER);
  const [error] = await to(_startRebookFlow(store));
  if (error) {
    captureException(error);
    // notes: only remove loading state when an error happened, because otherwise
    // the user can click multiple times on the button which blows up the backend
    store.commit(m.DECREMENT_ITINERARY_LOADING_COUNTER);
    // The dead cats are the sacrifices of the war
    // The start rebook flow use too much store, it need to refactor in the future.
    return flightDisruptionActions.addErrors(apiErrorsOrDefault(error));
  }
};

export const _startRebookFlow = async (store) => {
  await summaryActions.fetchPastPurchases(store);
  summaryActions.resetTotalAmount(store);
  const response = await BookingService.rebook();

  coreBookingActions.setIsCancellationRebookFlow(store);

  flightChangeActions.setBooking(store, {
    ...response.data,
    rebook: true,
  });
  sendGaEvent({
    category: 'Schedule Change',
    action: 'Click',
    label: 'Rebook',
    nonInteractive: true,
  });

  goToFlightChange();
};

export const validateCheckInPassengers = async ({ commit, state }) => {
  const passengers = formatValidationPassengers(getters.checkInSelectedPassengers(state));
  const response = await CheckInService.validateCheckInPassengers(passengers);
  commit(m.UPDATE_BANNED_PASSENGERS, formatBannedPassengers(response.data.passengers));
};

export const hideBundleUpgradeModal = createAction(
  m.UNSET_IS_UPGRADE_BUNDLE_MODAL_VISIBLE
);
export const resetBannedPassengers = createAction(m.RESET_BANNED_PASSENGERS);

export const setIsSuccessfulBookingLoaded = createAction(
  m.SET_IS_SUCCESSFUL_BOOKING_LOADED
);

export const unsetIsSuccessfulBookingLoaded = createAction(
  m.UNSET_IS_SUCCESSFUL_BOOKING_LOADED
);

export const initializeItinerary = async (store, initialFlow) => {
  const startTime = performance.now();

  const { state } = store;

  // notes the errors handled on the itinerary load
  if (getters.hasErrors(state)) return;

  hideBundleUpgradeModal(store);
  upsellActions.resetPostBookingUpsellInfo(store);
  killTheCryingMushroom(store);
  await fetchCheckInPassengers(store);

  if (!getters.isHeld(state))
    await fetchPassengersAndAncillaries(store, { withSummary: true });
  if (userGetters.isLoggedIn(state) && getters.canQueryPostBookingUpsell(state)) {
    _fetchPostBookingBundleUpsell(store);
  }
  localeActions.fetchCurrencies(store);
  startInitialFlow(store, initialFlow);

  const elapsedTime = performance.now() - startTime;
  const [navigationTiming] = performance.getEntriesByType('navigation');

  gtm({
    event: 'itinerary_loading_counter',
    dom_complete: navigationTiming?.domComplete,
    itinerary_load_start: startTime,
    itinerary_load_time: elapsedTime,
  });
};

const _fetchPostBookingBundleUpsell = async (store) => {
  const { commit } = store;
  commit(m.INCREMENT_ITINERARY_LOADING_COUNTER);
  const [assetError] = await to(fetchBundlesForUpsell(store));
  const [error] = await to(upsellActions.fetchPostBookingBundleUpsell(store));
  commit(m.DECREMENT_ITINERARY_LOADING_COUNTER);

  if (error) {
    captureException(error);
    commit(m.ADD_ITINERARY_ERRORS, apiErrors(error));
  }
  if (assetError) {
    captureException(assetError);
    commit(m.ADD_ITINERARY_ERRORS, apiErrors(assetError));
  }
};

const fetchBundlesForUpsell = async (store) => {
  const data = { params: { isPostBooking: true } };
  const response = await getBundles(data);
  const bundles = pathOr([], 'data.bundles', response);
  ancillariesActions.saveBundles(store, updateBundleAncillaryServices(bundles));
};

export const resetCurrentTypeAndSelectedPassenger = createAction(
  m.RESET_CURRENT_TYPE_AND_SELECTED_PASSENGER
);

export const updateItinerarySelectedPassenger = createAction(
  m.UPDATE_ITINERARY_SELECTED_PASSENGER
);

export const setCurrentType = createAction(m.SET_CURRENT_TYPE);

export const setSelectedCheckInPassenger = createAction(
  m.SET_SELECTED_CHECK_IN_PASSENGER
);

export const setCurrentFlight = createAction(m.SET_CURRENT_FLIGHT);

export const setIsAddBagsFlow = createAction(m.SET_IS_ADD_BAGS_FLOW);

export const resetSelectedCheckInPassenger = createAction(
  m.RESET_SELECTED_CHECK_IN_PASSENGER
);

export const selectAutoCheckIn = createAction(m.SELECT_AUTO_CHECK_IN);

export const unselectAutoCheckIn = createAction(m.UNSELECT_AUTO_CHECK_IN);

export const fetchItineraryAdvertisementBanners = async ({ commit }) => {
  const [error, response] = await to(PromotionService.getItineraryPagePromoImages());
  if (error) return captureAndLogException(error);
  commit(m.SET_ADVERTISEMENT_BANNER, response.data);
};

export const setAutoCallMethodName = createAction(m.SET_AUTO_CALL_METHOD_NAME);

export const setSelectedTabDirection = createAction(m.SET_SELECTED_TAB_DIRECTION);

export const setPassengerSeatUnitDesignator = createAction(
  m.SET_PASSENGER_SEAT_UNIT_DESIGNATOR
);

export const setIsAddFlightExitIntentModalSeen = createAction(
  m.SET_IS_ADD_FLIGHT_EXIT_INTENT_MODAL_SEEN
);

export const setIsComingFromSeatModificationCI = createAction(
  m.SET_IS_COMING_FROM_SEAT_MODIFICATION_CI
);
