import { stringify } from 'querystring';
import log from 'loglevel';
import {
  DEPARTURE_DATE,
  OUTBOUND_FLIGHT_NAME,
  RETURN_DATE,
  RETURN_FLIGHT_NAME,
  STATION_ARRIVAL,
  STATION_DEPARTURE,
} from '~/constants';
import path from '~/utils/fp/path';
import curry from '~/utils/fp/curry';
import either from '~/utils/fp/either';
import equals from '~/utils/fp/equals';
import filter from '~/utils/fp/filter';
import head from '~/utils/fp/head';
import identity from '~/utils/fp/identity';
import ifElse from '~/utils/fp/if-else';
import orElse from '~/utils/fp/or-else';
import pathOr from '~/utils/fp/path-or';
import compose from '~/utils/fp/compose';
import isEmpty from '~/utils/object/is-empty';
import isNotEmpty from '~/utils/object/is-not-empty';
import { findDirectStationConnectionsByIata } from '~/utils/resource';
import { addDays, toDefaultFormat, isAfter } from '~/utils/date';
import { isMobileScreen } from '~/utils/browser';
import * as resourceGetters from '../resources/getters';
import { isPartnerRedirectVariantB as isPartnerRedirectVariantBGetter } from '../feature/getters';
import { selectedSearchTab } from '../volatile/getters';
import { BOOKING_SEARCH_BASE_URL, BOOKING_DOT_COM_SEARCH_BASE_URL } from './internal';

export const searchProp = curry((prop, state) => path(prop, state.search));

export const searchPropOr = curry((defaultValue, prop, state) =>
  pathOr(defaultValue, prop, state.search)
);

export const isFlightListLoaded = (state) =>
  hasOutboundFlights(state) || hasReturnFlights(state);

/**
 * @type {(state: {}) => string[]}
 */
export const departureStationAlternatives = searchPropOr(
  [],
  'departureStationAlternatives'
);

/**
 * @type {(state: {}) => string[]}
 */
export const arrivalStationAlternatives = searchPropOr([], 'arrivalStationAlternatives');

/**
 * @type {(state: {}) => boolean}
 */
export const isDomestic = searchPropOr(false, 'isDomestic');

/**
 * @type {(state: {}) => boolean}
 */
export const isBundleCompositionWarningTextVisible = searchPropOr(
  false,
  'isBundleCompositionWarningTextVisible'
);

/**
 * @type {(state: {}) => Price}
 */
export const outboundTravelPartnerFee = searchPropOr(null, 'outbound.fees.travelPartner');

/**
 * @type {(state: {}) => Price}
 */
export const returnTravelPartnerFee = searchPropOr(null, 'return.fees.travelPartner');

/**
 * @type {(state: {}) => []}
 */
export const outboundBundles = searchPropOr([], 'outbound.bundles');

/**
 * @type {(state: {}) => []}
 */
export const returnBundles = searchPropOr([], 'return.bundles');

/**
 * @type {(state: {}) => []}
 */
export const outboundFlights = searchPropOr([], 'outbound.flights');

/**
 * @type {(state: {}) => []}
 */
export const returnFlights = searchPropOr([], 'return.flights');

/**
 * @type {(state: {}) => boolean}
 */
const hasOutboundFlights = compose(isNotEmpty, outboundFlights);

/**
 * @type {(state: {}) => boolean}
 */
const hasReturnFlights = compose(isNotEmpty, returnFlights);

/**
 * @type {(state: {}) => boolean}
 */
const isHorizontalHotelTabActive = (state) => selectedSearchTab(state) === 'hotel';

export const bookingSearchParams = (state) => {
  const search = state.search;
  const { adult, child, infant } = search.passengers;

  // key order is very important, do not change!!!
  return {
    departureStationIata: search.departureStation.iata,
    arrivalStationIata: search.arrivalStation.iata,
    departureDate: toDefaultFormat(search.departureDate),
    returnDate: search.returnDate ? toDefaultFormat(search.returnDate) : 'null',
    adult,
    child,
    infant,
    rescueFareCode: search.rescueFareCode || 'null',
  };
};

// returns booking search url, eg.:
// "/booking/select-flight/BUD/LTN/2017-06-30/null/2/1/1/0/RCGR7A"
export const bookingSearchUrl = (state) => {
  const search = state.search;
  if (!search || isEmpty(search)) {
    log.warn('invalid search store');
    return BOOKING_SEARCH_BASE_URL;
  }
  const params = [
    search.departureStation.iata,
    search.arrivalStation.iata,
    toDefaultFormat(search.departureDate),
    search.returnDate ? toDefaultFormat(search.returnDate) : 'null',
    search.passengers.adult,
    search.passengers.child,
    search.passengers.infant,
    search.rescueFareCode || 'null',
    search.outboundDepartureDate || 'null',
    search.outboundBundle || 'null',
    search.returnDepartureDate || 'null',
    search.returnBundle || 'null',
  ].join('/');
  return `${BOOKING_SEARCH_BASE_URL}${params}`;
};

// for the popunder partner
export const bookingDotComSearchUrl = (state) => {
  const isHotelTabActive = isHorizontalHotelTabActive(state);
  const search = state.search;
  const isPartnerRedirectVariantB = isPartnerRedirectVariantBGetter(state);
  const departureDate = search.departureDate;
  let returnDate = search.returnDate || departureDate;
  const checkinDate = search.checkinDate;
  let checkoutDate = search.checkoutDate || checkinDate;

  // bookingcom search form breaks if the date diff is gt 30 days
  // but according to wizz, we can just skip the whole popunder thing
  const maxReturnDate = compose(toDefaultFormat, addDays(30))(departureDate);
  if (isAfter(returnDate, maxReturnDate)) {
    return false;
  }

  // their form will not work with same day values
  if (isHotelTabActive && checkinDate === checkoutDate) {
    checkoutDate = compose(toDefaultFormat, addDays(1))(checkinDate);
  } else if (!isHotelTabActive && returnDate === departureDate) {
    returnDate = compose(toDefaultFormat, addDays(1))(departureDate);
  }

  let trackingLabel = isHotelTabActive
    ? 'pagehome-sbx-2320530-click'
    : isMobileScreen()
    ? 'mobsbsearch-see-1280102-click'
    : 'pagesbsearch-see-1280102-click';

  if (isPartnerRedirectVariantB) {
    trackingLabel = isHotelTabActive
      ? 'pagehome-sbx-2320530-click'
      : 'pagehome-see-2410864-click';
  }

  let params = {
    aid: isHotelTabActive ? 1243829 : isPartnerRedirectVariantB ? 2410864 : 1280102,
    checkin: isHotelTabActive ? checkinDate : departureDate,
    checkout: isHotelTabActive ? checkoutDate : returnDate,
    iata_orr: 1,
    iata: isHotelTabActive ? search.destination.iata : search.arrivalStation.iata,
    label: trackingLabel,
    group_adults: search.passengers.adult,
  };
  if (search.passengers.child) {
    params.group_children = search.passengers.child;
    params.age = '12+'; // this is required for the group_children!
  }
  params = stringify(params, ';');
  return `${BOOKING_DOT_COM_SEARCH_BASE_URL}?${params}`;
};

export const destination = searchProp('destination');

export const bookingDestination = searchProp('bookingDestination');

export const checkinDate = searchProp('checkinDate');

export const checkoutDate = searchProp('checkoutDate');

export const departureDate = searchProp('departureDate');

export const returnDate = searchProp('returnDate');

export const isOneWayRoute = compose(isEmpty, searchProp('returnDate'));

export const formattedDepartureDate = (state) =>
  isNotEmpty(searchProp('departureDate', state))
    ? toDefaultFormat(searchProp('departureDate', state))
    : '';

export const formattedReturnDate = (state) =>
  isNotEmpty(searchProp('returnDate', state))
    ? toDefaultFormat(searchProp('returnDate', state))
    : '';

export const arrivalStationIata = (state) => state.search.arrivalStation.iata;

export const departureStationIata = (state) => state.search.departureStation.iata;

export const departureStation = searchProp('departureStation');

export const arrivalStation = searchProp('arrivalStation');

export const departureStationSuggestion = searchPropOr('', 'departureStation.suggestion');

export const arrivalStationSuggestion = searchPropOr('', 'arrivalStation.suggestion');

export const possibleDatesForFlight = searchProp('possibleDatesForFlight');

export const wdcMemberships = searchPropOr([], 'wdcMemberships');

export const wdcRenewalPrice = searchProp('bookingCurrencyRenewalPrice');

export const hasReachedLastDateWithFlight = searchProp('hasReachedLastDateWithFlight');

export const getCheapestFarePrice = (state, type) => {
  const prices = state.search[type].flights
    .filter((flight) => flight.fares.length)
    .reduce((discountedPrices, flight) => {
      const fares = flight.fares.filter(
        (fare) => isEmpty(fare.wdc) === state.search.wdc && !fare.soldOut
      );
      return [...discountedPrices, ...fares.map((fare) => fare.discountedPrice.amount)];
    }, []);

  return isNotEmpty(prices) ? Math.min(...prices) : 0;
};

export const cheapestOutboundFarePrice = (state) =>
  getCheapestFarePrice(state, OUTBOUND_FLIGHT_NAME);

export const cheapestReturnFarePrice = (state) =>
  getCheapestFarePrice(state, RETURN_FLIGHT_NAME);

export const passengers = searchProp('passengers');

export const wdc = searchProp('wdc');

/**
 * @type {(state: State) => number}
 */
export const numberOfAdultPassengers = searchProp('passengers.adult');

/**
 * @type {(state: State) => number}
 */
export const numberOfChildPassengers = searchProp('passengers.child');

export const numberOfInfantPassengers = searchProp('passengers.infant');

export const numberOfIndependentPassengers = (state) => {
  return numberOfAdultPassengers(state) + numberOfChildPassengers(state);
};

export const outboundDepartureDate = searchProp('departureDate');

export const returnDepartureDate = searchProp('returnDate');

export const isDuplicateFlightSearch = (state) => {
  const {
    departureStation,
    arrivalStation,
    departureDate,
    returnDate,
    passengers,
    savedSearchParams,
  } = state.search;
  return equals(savedSearchParams, {
    departureStation,
    arrivalStation,
    departureDate,
    returnDate,
    passengers,
  });
};

// New search
export const stationSearchTerm = searchProp('stationSearchTerm');

export const focusedField = searchProp('focusedField');

export const isDepartureStationFocused = compose(equals(STATION_DEPARTURE), focusedField);

export const isArrivalStationFocused = compose(equals(STATION_ARRIVAL), focusedField);

const isStationMatchesSearchTerm = curry((state, station) => {
  const textFilter = stationSearchTerm(state);

  if (isEmpty(textFilter)) return true;

  const { countryName, countryCode, shortName, iata } = station;

  return [countryCode, countryName, shortName, iata].some((item) =>
    item.toLowerCase().includes(textFilter.toLowerCase())
  );
});

export const stations = resourceGetters.stationsWithFakes;

export const stationsByCurrentSelection = (state) =>
  compose(
    filter(isStationMatchesSearchTerm(state)),
    ifElse(
      () => isDepartureStationFocused(state),
      identity,
      () =>
        findDirectStationConnectionsByIata(departureStationIata(state), stations(state))
    ),
    stations
  )(state);

export const suggestionByCurrentSelection = compose(
  orElse({}),
  head,
  stationsByCurrentSelection
);

export const isDepartureDateFocused = compose(equals(DEPARTURE_DATE), focusedField);

export const isReturnDateFocused = compose(equals(RETURN_DATE), focusedField);

export const isAnyDateFieldFocused = either(isDepartureDateFocused, isReturnDateFocused);

export const flightDates = searchProp('flightDates');

export const searchMinDate = (state) =>
  isReturnDateFocused(state)
    ? toDefaultFormat(outboundDepartureDate(state))
    : searchProp('searchMinDate', state);

export const searchMaxDate = searchProp('searchMaxDate');

export const originalDepartureDate = searchProp('originalDepartureDate');

export const flightDateSearchErrors = searchProp('flightDatesSearchErrors');

export const rescueFareCode = searchProp('rescueFareCode');

export const hasFlightDateSearchErrors = compose(isNotEmpty, flightDateSearchErrors);

export const isTimetable = searchProp('isTimetable');

export const isPromotions = searchProp('isPromotions');

export const isBuyingPrivilegePassAllowed = searchProp('isPrivilegePassAllowed');

export const activeFilter = searchProp('activeFilter');
