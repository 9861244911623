/* eslint import/no-cycle: "warn" */
import querystring from 'querystring';
import {
  BREAKPOINTS,
  BREAKPOINT_DESKTOP,
  BREAKPOINT_PHABLET,
  BREAKPOINT_TABLET,
  BREAKPOINT_MOBILE,
} from '~/constants';
import { SS_LOYAL_CUSTOMER_TRACKED } from '~/constants/session-storage';
import complement from '~/utils/fp/complement';
import { isNotServer, isServer } from '~/utils/ssr';
import { getCultures } from '~/utils/global-value/cultures';
import isEmpty from '~/utils/object/is-empty';
import {
  removeLocalItem,
  setLocalItemRaw,
  setSessionItem,
  clearSessionStorageWithWhiteList,
} from '../storage';
import { emit } from '../event-bus';
import removeQueryString from '../methods/remove-query-string';
import parseUrl from './parse-url';

export { getExposedUrl } from './get-exposed-url';
export { setHref } from './set-href';
export { toExposedUrl } from './to-exposed-url';

let ua = '';
if (!isServer()) {
  ua = window.navigator.userAgent.toLowerCase();
}

export const getScrollBarWidth = () => {
  return window.innerWidth - document.body.clientWidth;
};

// edge is NOT ie anymore (do not confuse Edge browser with dev toolbar's edge (which is "bleeding edge" ie))
export const isEdge = () => ua.includes('edge/');

export const isAndroid = () => ua.includes('android');

const isIphone = () => ua.includes('iphone');

const isIpad = () => ua.includes('ipad');

export const isIosDevice = () => isIphone() || isIpad();

export const isNotIosDevice = complement(isIosDevice);

export const isSafari = () => {
  const regex = /^((?!chrome|android).)*safari/i;
  return Boolean(ua.match(regex));
};

export const isInAppBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const rules = ['WebView', '(iPhone|iPod|iPad)(?!.*Safari/)', 'Android.*(wv|.0.0.0)'];
  const regex = new RegExp(`(${rules.join('|')})`, 'ig');
  return Boolean(userAgent.match(regex));
};

// yes, this is ugly, but it's more reliable than the css supports-ms-whatever hacks
function addIeClassToBody() {
  if (isEdge()) {
    document.body.classList.add('edge');
  }
}

function addGeckoClassToBody() {
  if (ua.includes('gecko') && !ua.includes('like gecko')) {
    document.body.classList.add('gecko');
  }
}

export function removeSkeletonCss() {
  document.body.classList.remove('skeleton-loading');
  const contentSkeleton = document.querySelector('.skeleton-loading');
  if (!contentSkeleton) return;
  contentSkeleton.classList.remove('skeleton-loading');
}

export const addBrowserHacks = () => {
  addIeClassToBody();
  addGeckoClassToBody();
};

// mobile, phablet, tablet, desktop
export const getCurrentBreakpoint = (reverse = false) => {
  const bp = [...BREAKPOINTS].sort((a, b) => {
    const sorted = reverse ? a[1] - b[1] : b[1] - a[1];
    return sorted;
  });

  for (const [key, value] of bp) {
    const prop = reverse ? 'max-width' : 'min-width';
    const mq = `screen and (${prop}: ${value}px)`;
    if (window.matchMedia(mq).matches) {
      return key;
    }
  }
  return reverse ? 'desktop' : 'mobile';
};

// initializes a multi tab listener, may be run only once
// (it's pretty hard to test, so the spec is skipped for now)
let _multiTabListenerActive = false;
export const addMultiTabListener = () => {
  const KEY = 'multi-tab';
  const KEY_ALL = 'multi-tab-all';
  const prematureInitTimeout = 2000;
  const propagationTimeout = 1000;
  const initTime = Date.now();
  // probably not that important with the raw functions from storage.js
  if (!hasLocalStorage() || _multiTabListenerActive) return;

  // this WILL fire multiple times (5 open tabs = 5 event per tab), deal with that on the ui please
  window.addEventListener('storage', (event) => {
    if (event.key === KEY_ALL) {
      setTimeout(() => removeLocalItem(KEY_ALL), propagationTimeout);
      emit('browser_otherTabOpen', {
        timestamp: event.newValue,
        diff: Date.now() - event.newValue,
      });
    }
    // not our "signal"
    if (event.key !== KEY) {
      return;
    }
    // still hot, this is a newly loaded tab
    if (event.newValue - initTime < prematureInitTimeout) {
      return;
    }
    setLocalItemRaw(KEY_ALL, String(event.newValue));
  });
  setLocalItemRaw(KEY, String(Date.now()));
  _multiTabListenerActive = true;
};

/**
 * @type {() => boolean}
 */
export const hasLocalStorage = () =>
  Boolean(isServer() || ('localStorage' in window && window.localStorage));

export const setMetaTitle = (text) => (document.title = text);

export const setMetaDescription = (text) => {
  const meta = document.querySelector('meta[name="description"]');
  if (meta) {
    meta.setAttribute('content', text);
  }
};

export const setCanonicalUrl = (url) => {
  const canonicalTag = document.querySelector('link[rel="canonical"]');
  if (!canonicalTag) return;
  canonicalTag.setAttribute('href', url);
};

export const parseQueryString = (queryString) => {
  const str = queryString || (isServer() ? '' : window.location.search);

  return querystring.parse(str.replace(/^\?/, ''));
};

export const setBrowserToLeaveState = (showLoader = true) => {
  const emptyIcon = document.createElement('canvas').toDataURL('image/x-icon');

  if (showLoader) {
    document.body.classList.add('loader-combined');
  }

  document.title = '...';
  document.querySelector('link[rel="shortcut icon"]').href = emptyIcon;
};

// add a huge loader, remove title and favicon
// (useful for slow nav away events)
export const leaveSite = (targetUrl, doLeave) => {
  setBrowserToLeaveState();

  // trash everything on the way out
  if (targetUrl && targetUrl.startsWith('http')) {
    clearSessionStorageWithWhiteList([SS_LOYAL_CUSTOMER_TRACKED]);
    setSessionItem('redirect-to', targetUrl);

    if (doLeave) {
      window.location.replace(targetUrl);
    }
  }
};

// for stubs
export const getHref = () => (isNotServer() ? window.location.href : getBaseUrl());

export const getHash = (raw = false) => {
  const hash = isServer() ? '' : window.location.hash;
  return raw ? hash : hash.replace(/^#/, '');
};

export const getBaseUrl = () => getHref().replace(/\/#.*/, '');

export const getPathName = () => {
  return window.location.pathname;
};

export const triggerHashChange = () => {
  // hashchange in IE is utterly broken (even in ie 11)
  if (!window.HashChangeEvent) {
    emit('browser_fakeHashChangeEvent');
    return;
  }

  window.dispatchEvent(new HashChangeEvent('hashchange'));
};

export const redirectWithReplaceState = (target = '/') => {
  history.replaceState(null, null, target);
  // hashchange in IE is utterly broken (even in ie 11)
  if (!window.HashChangeEvent) {
    emit('browser_fakeHashChangeEvent');
    return;
  }
  if (target.includes('#')) {
    triggerHashChange(); // uhh, yes, this is needed
  }
};

export const clearHashFromUrl = () => {
  return window.history.pushState('', document.title, window.location.pathname);
};

// smaller mobile
export const isSmallMobileScreen = () => {
  return isServer()
    ? false
    : window.matchMedia(`(max-width: ${BREAKPOINT_MOBILE - 1}px)`).matches;
};

// mobile
export const isAtLeastMobileScreen = () => {
  return isServer()
    ? false
    : window.matchMedia(`(min-width: ${BREAKPOINT_MOBILE}px)`).matches;
};

export const isMobileScreen = () => {
  return isServer()
    ? false
    : window.matchMedia(`(max-width: ${BREAKPOINT_PHABLET - 1}px)`).matches;
};

// phablet
export const isAtLeastPhabletScreen = () => {
  return isServer()
    ? false
    : window.matchMedia(`(min-width: ${BREAKPOINT_PHABLET}px)`).matches;
};

export const isAtMostPhabletScreen = () => {
  return isServer()
    ? false
    : window.matchMedia(`(max-width: ${BREAKPOINT_TABLET - 1}px)`).matches;
};

export const isPhabletScreen = () => {
  return isAtLeastPhabletScreen() && isAtMostPhabletScreen();
};

// tablet
// originally we named it as isDesktopScreen, but it was misleading,
// because original breakpoint was tablet in isDesktopScreen (min-width: ${BREAKPOINT_TABLET}px)
export const isAtLeastTabletScreen = () => {
  return isServer()
    ? false
    : window.matchMedia(`(min-width: ${BREAKPOINT_TABLET}px)`).matches;
};

export const isAtMostTabletScreen = () => {
  return isServer()
    ? true
    : window.matchMedia(`(max-width: ${BREAKPOINT_DESKTOP - 1}px)`).matches;
};

export const isTabletScreen = () => {
  return isAtLeastTabletScreen() && isAtMostTabletScreen();
};

// desktop
export const isAtLeastDesktopScreen = () => {
  return isServer()
    ? true
    : window.matchMedia(`(min-width: ${BREAKPOINT_DESKTOP}px)`).matches;
};

/**
 * @param path string, raw path
 * @param {Object} [state] store state - we pass this value when the store isn't yet initialized
 */
export function getPathWithoutLanguage(path, state) {
  const parts = path.startsWith('/')
    ? path.slice(1).replace(/\?.*/, '').split('/')
    : path.replace(/\?.*/, '').split('/');

  const storeState = isServer()
    ? require('~/store').getStore().state
    : isEmpty(state)
    ? {}
    : state;
  const cultures = getCultures(storeState);

  const filteredPathParts = parts.filter(
    (part) => !Object.keys(cultures).includes(part.toLowerCase())
  );
  return filteredPathParts.length === 0
    ? '/main-page'
    : `/${filteredPathParts.join('/')}`;
}

/**
 *
 * @param {Object} [url=window.location.href] an absolute url
 * @param {Object} [route] is vue router to route is available, check root route
 * @param {Object} [state] store state - we pass this value when the store isn't yet initialized
 * @return {Boolean} Returns true if user at main page.
 */
export const isMainPage = (url = window.location.href, route, state) => {
  const { hash, pathname } = parseUrl(url);

  if (hash.startsWith('#/booking') || hash.startsWith('#/check-in')) return false;
  if (hash.startsWith('#/email-verification')) return true;

  const path = pathname.replace(/\/$/, '');
  const pathWithoutLanguage = getPathWithoutLanguage(path, state);
  const routePath = route?.path ?? '/';

  return (
    ['/', '/main-page', '/rescue', '/validation'].includes(pathWithoutLanguage) &&
    routePath === '/'
  );
};

export const isCmsFlightChangePage = () => {
  if (isServer()) return false;
  const { pathname } = parseUrl(window.location.href);
  const pathWithoutLanguage = getPathWithoutLanguage(pathname);
  return pathWithoutLanguage === '/flight-change';
};

// TODO: it isn't the best way to detect whether a device is a touch-controlled one or not...
// http://www.stucox.com/blog/you-cant-detect-a-touchscreen/
export const isTouchDevice = () => 'ontouchstart' in window;

export const removeUtmQueryParamsFromUrl = () => {
  const location = window.location;
  if (location.hash.includes('utm')) {
    const cleanUrl = location.hash
      .replace(/\??utm_[^&]+&?/g, '')
      .replace(/&$/, '')
      .replace(/^\?$/, '');
    if (window.history.replaceState) {
      history.replaceState({}, '', cleanUrl);
    } else {
      location.hash = removeQueryString(location.hash);
    }
  }
};

export const resizeObserver = (callback = () => {}) => {
  const ResizeObserver = getResizeObserver();
  return new ResizeObserver((entries) => {
    window.requestAnimationFrame(() => {
      if (!Array.isArray(entries) || entries.length === 0) return;
      callback(entries);
    });
  });
};

const getResizeObserver = () => {
  const module = require('resize-observer-polyfill');
  // note: webpack works with es module version, node works with cjs module
  //  version so we need to support both
  return module.default || module;
};

export const removeQueryStringWithoutReload = () => {
  const { protocol, host, pathname = '' } = window.location;
  const url = `${protocol}//${host}${pathname}`;
  window.history.replaceState({ path: url }, '', url);
};

export const isServiceWorkerAvailable = () => 'serviceWorker' in navigator;
export const isNotificationAvailable = () => 'Notification' in window;

export const isPasswordResetRoute = () => {
  const { hash } = parseUrl(window.location.href);

  return hash.startsWith('#/password-reset');
};

export const isUrl = (str) => {
  const regex = /^(http(s):\/\/.)[\w#%+.:=@~-]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)$/;
  return regex.test(str);
};

export const removeFeatureToggleTestGuid = (featureToggleGuid) => {
  if (!featureToggleGuid) return;

  const { protocol, host, pathname = '' } = window.location;
  const url = `${protocol}//${host}${pathname.replace(featureToggleGuid, '')}`;
  window.history.replaceState({ path: url }, '', url);
};

export const createBrowserInformation = () => {
  const browserInformation = {
    timeZone: new Date().getTimezoneOffset(),
  };

  if (window && window.screen) {
    browserInformation.screenWidth = window.screen.width;
    browserInformation.screenHeight = window.screen.height;
    browserInformation.colorDepth = window.screen.colorDepth;
  }

  if (window && window.navigator) {
    browserInformation.userAgent = window.navigator.userAgent;
    browserInformation.language =
      window.navigator.language ?? window.navigator.userLanguage;
  }

  return browserInformation;
};
