export const isBlackListedUrl = (url) => {
  const api = url.toLowerCase().replace(/.+\/api\//gi, '');
  return ENDPOINT_BLACK_LIST.some((item) => api.startsWith(item.toLowerCase()));
};

const ENDPOINT_BLACK_LIST = [
  'asset/country',
  'asset/culture',
  'asset/featureToggling',
  'asset/currencies',
  'asset/cultures',
  'asset/disruptedFlights',
  'asset/map',
  'asset/serviceFees',
  'asset/timeout',
  'asset/yellowribbon',
  'banners/homepage', // CMS api: homePageBannersUrl
  'information/browserSupport',
  'search/flightDates',
];
