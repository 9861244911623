export const BASE_URL_FARE_FINDER = 'fareFinder';

// new hash based on vue router instead of routie
const hashLegacyParams = ['wdc', 'timetable', 'departureDate', 'returnDate'];
const hashSearchParams = [
  'wdc',
  'timetable',
  'adultCount',
  'childCount',
  'infantCount',
  'departureDate',
  'returnDate',
];
const hashAutoSearchSearchParams = [
  ...hashSearchParams,
  ...['travelDuration', 'destinationCategory', 'priceRange'],
];
const hashExtendedSearchParams = [
  'wdc',
  'timetable',
  'promotions',
  'adultCount',
  'childCount',
  'infantCount',
  'departureDate',
  'returnDate',
];

export const hashParamsTypes = {
  [hashLegacyParams.length]: hashLegacyParams,
  [hashSearchParams.length]: hashSearchParams,
  [hashAutoSearchSearchParams.length]: hashAutoSearchSearchParams,
  [hashExtendedSearchParams.length]: hashExtendedSearchParams,
};

export const airportMap = Object.freeze({
  aberdeen: 'abz',
  algherosardinia: 'aho',
  alesund: 'aes',
  alesundtrzeba: 'aes',
  alghero: 'aho',
  alicante: 'alc',
  antalya: 'ayt',
  arad: 'arw',
  baku: 'gyd',
  barcelonagirona: 'gro',
  barcelona: 'bcn',
  bari: 'bri',
  basel: 'mlh',
  baselmulhouse: 'mlh',
  baselmulhousefreiburg: 'mlh',
  belfast: 'bfs',
  belfastinternational: 'bfs',
  belgrad: 'beg',
  belgrade: 'beg',
  bergamo: 'bgy',
  bergen: 'bgo',
  berlin: 'sxf',
  berlinschoenefeld: 'sxf',
  billund: 'bll',
  birmingham: 'bhx',
  bologna: 'blq',
  bordeaux: 'bod',
  bordeauxmerignac: 'bod',
  bordeauxmérignac: 'bod',
  bourgas: 'boj',
  bourgasblacksea: 'boj',
  bratislava: 'bts',
  bristol: 'brs',
  'bristol ': 'brs',
  brno: 'brq',
  brussels: 'crl',
  brusselscharleroi: 'crl',
  bucaresti: 'otp',
  bucharest: 'otp',
  bucharesthcoanda: 'otp',
  bucharesthcoandabagaji: 'otp',
  bucharesthyolanda: 'otp',
  buda: 'bud',
  budapest: 'bud',
  budapeste: 'bud',
  budapestu: 'bud',
  bukarest: 'otp',
  burgas: 'boj',
  burgasz: 'boj',
  bydgoszcz: 'bzg',
  catania: 'cta',
  chisinau: 'kiv',
  cluj: 'clj',
  clujnapoc: 'clj',
  clujnapoca: 'clj',
  clujnapoka: 'clj',
  cologne: 'cgn',
  colonge: 'cgn',
  constanta: 'cnd',
  copenhagen: 'cph',
  corfu: 'cfu',
  cork: 'ork',
  craiova: 'cra',
  craiovaromania: 'cra',
  debrecen: 'deb',
  doncastersheffield: 'dsa',
  sheffield: 'dsa',
  donetsk: 'dok',
  dortmund: 'dtm',
  dubai: 'dwc',
  eindhoven: 'ein',
  faroeislands: 'fae',
  'flesland ': 'bgo',
  forlibologna: 'frl',
  frankfurt: 'hhn',
  frankfurthahn: 'hhn',
  friedrichshafen: 'fdh',
  fuerteventura: 'fue',
  fuerteventuracanaryislands: 'fue',
  gdansk: 'gdn',
  gdanskle: 'gdn',
  geneva: 'gva',
  glasgow: 'gla',
  glasgowprestwick: 'gla',
  gothenburg: 'gse',
  gothenburgcity: 'gse',
  gothenburglandvetter: 'got',
  grenoble: 'gnb',
  grenoblelyon: 'gnb',
  groningen: 'grq',
  hamburg: 'ham',
  hamburgluebeck: 'lbc',
  hannover: 'haj',
  haugesund: 'hau',
  heraklion: 'her',
  heraklioncrete: 'her',
  hurghada: 'hrg',
  iasi: 'ias',
  ibiza: 'ibz',
  ioanniskapodistrias: 'cfu',
  istanbul: 'saw',
  karlsruhebadenbaden: 'fkb',
  katovice: 'ktw',
  katowice: 'ktw',
  katowicechopin: 'ktw',
  kaunas: 'kun',
  kharkov: 'hrk',
  kiev: 'iev',
  kievzhulhany: 'iev',
  zhulyany: 'iev',
  konstanca: 'cnd',
  kosice: 'ksc',
  kristiansand: 'krs',
  kutaisi: 'kut',
  köln: 'cgn',
  lamezia: 'suf',
  lameziaterme: 'suf',
  lanzarote: 'ace',
  lanzarotecanaryislands: 'ace',
  larnaca: 'lca',
  lille: 'lil',
  lillelesquin: 'lil',
  lisabon: 'lis',
  lisabona: 'lis',
  lisboa: 'lis',
  lisbon: 'lis',
  lisbonezt: 'lis',
  liverpool: 'lpl',
  ljubljana: 'lju',
  london: 'ltn',
  'london gatwick': 'lgw',
  londonluton: 'ltn',
  londresluton: 'ltn',
  lublin: 'luz',
  lviv: 'lwo',
  lyon: 'lys',
  lyonsaintexupery: 'lys',
  lyonsaintexupéry: 'lys',
  maastricht: 'mst',
  maastrichtaachen: 'mst',
  madrid: 'mad',
  malaga: 'agp',
  malmo: 'mmx',
  malmö: 'mmx',
  malpensa: 'mxp',
  malta: 'mla',
  marseille: 'mrs',
  marseilleprovence: 'mrs',
  memmingen: 'fmm',
  memmingenmunichwest: 'fmm',
  memmingeno: 'fmm',
  milan: 'mxp',
  milanmalpensa: 'mxp',
  milano: 'bgy',
  milanobergamo: 'bgy',
  molde: 'mol',
  moscow: 'vko',
  moskow: 'vko',
  muenster: 'fmo',
  mures: 'tgm',
  nales: 'nap',
  napels: 'nap',
  naples: 'nap',
  napoca: 'clj',
  napoli: 'nap',
  nice: 'nce',
  nicecotedazur: 'nce',
  nis: 'ini',
  'nuremberg ': 'nue',
  ohrid: 'ohd',
  olsztyn: 'szy',
  olsztynmazury: 'szy',
  oslo: 'trf',
  oslosandefjordtorp: 'trf',
  ovda: 'vda',
  paderborn: 'pad',
  palanga: 'plq',
  palangaklaipeda: 'plq',
  palmademallorca: 'pmi',
  paris: 'bva',
  parisbeauvais: 'bva',
  parisbeauvals: 'bva',
  perugia: 'peg',
  pescara: 'psr',
  pisa: 'psa',
  podgorica: 'tgd',
  poprad: 'tat',
  popradtatry: 'tat',
  porto: 'opo',
  poznan: 'poz',
  prague: 'prg',
  reykjavik: 'kef',
  rhodes: 'rho',
  riga: 'rix',
  roma: 'cia',
  rome: 'cia',
  romeciampino: 'cia',
  romefiumicino: 'fco',
  rzym: 'fco',
  rzymfumicino: 'fco',
  saintetienne: 'ebu',
  saintetienneboutheon: 'ebu',
  saintétiennebouthéon: 'ebu',
  satumare: 'suj',
  sibiu: 'sbz',
  skavsta: 'nyo',
  skopje: 'skp',
  sofia: 'sof',
  sófia: 'sof',
  split: 'spu',
  stavanger: 'svg',
  stockholm: 'nyo',
  stockholmskavsta: 'nyo',
  'suceava ': 'scv',
  szczecin: 'szz',
  tallinn: 'tll',
  telaviv: 'tlv',
  telaviva: 'tlv',
  tenerife: 'tfs',
  tenerifecanaryislands: 'tfs',
  teneryfa: 'tfs',
  thessaloniki: 'skg',
  timisoara: 'tsr',
  tirgumures: 'tgm',
  torino: 'trn',
  toulouse: 'tls',
  toulouseblagnac: 'tls',
  trondheim: 'trd',
  turany: 'brq',
  turin: 'trn',
  turku: 'tku',
  tuzla: 'tzl',
  valencia: 'vlc',
  varna: 'var',
  varsóviachopin: 'waw',
  vaxjo: 'vxo',
  venezia: 'tsf',
  venice: 'tsf',
  venicetreviso: 'tsf',
  venicetrevisoletenky: 'tsf',
  verona: 'vrn',
  vilnis: 'vno',
  vilnius: 'vno',
  vilniusva: 'vno',
  wamozart: 'szg',
  mozart: 'szg',
  warsaw: 'waw',
  warsawchopin: 'waw',
  warsawmodlin: 'wmi',
  warschau: 'waw',
  warszawa: 'waw',
  warszawachopin: 'waw',
  wroclaw: 'wro',
  zakynthos: 'zth',
  zaragoza: 'zaz',
  zhurih: 'zrh',
});

export const SEARCH_TYPE_PREFILLED = 'prefilled';
export const SEARCH_TYPE_NORMAL = 'normal';

export const SMART_SEARCH_SORTING_OPTIONS = [
  { label: 'price-low-to-high', value: 'price' },
  { label: 'alphabetical-order', value: 'abc' },
  { label: 'date-order', value: 'date' },
];

export const SMART_SEARCH_SORTING_OPTIONS_PRICE = 'price';
export const SMART_SEARCH_SORTING_OPTIONS_ABC = 'abc';
export const SMART_SEARCH_SORTING_OPTIONS_DATE = 'date';

export const FILTER_ALL_OFFERS = 'all-offers';
export const FILTER_PROMOTION = 'promotion';
export const FILTER_NEW = 'new';

export const FILTER_LABEL_MAP = {
  [FILTER_ALL_OFFERS]: 'all-offers',
  [FILTER_PROMOTION]: 'fare-finder-icon-legend-promotion',
  [FILTER_NEW]: 'offer-new-tag',
};

export const FILTER_PARAM_MAP = {
  0: FILTER_ALL_OFFERS,
  1: FILTER_PROMOTION,
  2: FILTER_NEW,
};

export const STATUS_MESSAGE_TYPE_BRAND = 'brand';
export const STATUS_MESSAGE_TYPE_TIMETABLE_ERROR = 'timetableError';
export const STATUS_MESSAGE_TYPE_CHEAP_FLIGHTS_ERROR = 'cheapFlightsError';

export const STATUS_MESSAGE_MAP = {
  [STATUS_MESSAGE_TYPE_BRAND]: {
    id: 'no-origin',
    variant: 'brand',
    headingLabelKey: 'fare-finder-traveling-from-title',
    messageLabelKey: 'fare-finder-traveling-from-message',
  },
  [STATUS_MESSAGE_TYPE_TIMETABLE_ERROR]: {
    id: 'timetable-error',
    variant: 'error',
    headingLabelKey: 'something-went-wrong',
    messageLabelKey: 'something-went-wrong-message-timetable',
    buttonLabelKey: 'reload-offer',
  },
  [STATUS_MESSAGE_TYPE_CHEAP_FLIGHTS_ERROR]: {
    id: 'cheap-flights-error',
    variant: 'error',
    headingLabelKey: 'something-went-wrong',
    messageLabelKey: 'something-went-wrong-message',
    buttonLabelKey: 'show-all-offers',
  },
  [FILTER_ALL_OFFERS]: {
    id: 'no-all-offers',
    variant: 'error',
    headingLabelKey: 'something-went-wrong',
    messageLabelKey: 'something-went-wrong-message',
    buttonLabelKey: 'show-all-offers',
  },
  [FILTER_PROMOTION]: {
    id: 'no-promotion',
    variant: 'promo',
    headingLabelKey: 'no-promotion',
    messageLabelKey: 'no-promotion-message',
    buttonLabelKey: 'show-all-offers',
  },
  [FILTER_NEW]: {
    id: 'no-new-offers',
    variant: 'info',
    headingLabelKey: 'no-new-offers',
    messageLabelKey: 'no-new-offers-message',
    buttonLabelKey: 'show-all-offers',
  },
};
export const EMPTY_CONNECTION = 'empty';
export const FILLED_CONNECTION = 'filled';
export const COMPACT_SEARCH_BAR_HEIGHT = 47;
export const COMPACT_SEARCH_BAR_MOBILE_HEIGHT = 76;
export const SEARCH_BAR_HEIGHT = 120;
export const SEARCH_BAR_TABLET_HEIGHT = 202;
export const SEARCH_BAR_MOBILE_HEIGHT = 326;

export const PRICE_ALERT_MAX_COUNT = 5;
