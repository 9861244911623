import log from 'loglevel';
import to from 'await-to-js';

import { DEFAULT_ERROR_LABEL } from '~/constants';
import { HTTP_STATUS_INTERNAL_SERVER_ERROR } from '~/constants/http-status';
import pick from '~/utils/fp/pick';
import isNotUndefined from '~/utils/object/is-not-undefined';
import isNotEmpty from '~/utils/object/is-not-empty';
import { emitOnNextTick } from '~/utils/event-bus';
import { createAction } from '~/utils/store';
import {
  getPagePathForAnalyticsWithoutLanguage,
  sendGaEvent,
  setVar99Dimension,
} from '~/utils/analytics';
import { apiErrors, apiErrorsOrDefault } from '~/utils/services';
import { captureAndLogException, captureException } from '~/utils/logging';
import * as UserService from '~/services/user';
import { setLoyalCustomerFlag } from '~/services/ecommerce';
import * as gat from '~/store/action-types';

import {
  resetLoginRedirectUrl,
  showRegistrationPendingModal,
} from '~/store/modules/system/actions';
import { GA_EVENT_LABEL_LOGIN } from '~/constants/analytics';
import * as volatileMutationTypes from '../volatile/mutation-types';
import * as getters from './getters';
import * as m from './mutation-types';

export const updateLastSeenActive = createAction(m.UPDATE_LAST_SEEN_ACTIVE);

/**
 * @type {(store: Store) => void}
 */
export const updateProfile = createAction(m.UPDATE_PROFILE);

export const unsetShouldDisplayMobilePhoneWarningModal = createAction(
  m.UNSET_SHOULD_DISPLAY_MOBILE_PHONE_WARNING_MODAL
);

/**
 * @type {(store: Store) => void}
 */
export const setUserDateOfBirth = createAction(m.SET_USER_DATE_OF_BIRTH);

/**
 * @type {(store: Store) => void}
 */
export const setUserMobilePhone = createAction(m.SET_USER_MOBILE_PHONE);

/**
 * @type {(store: Store) => void}
 */
export const showCaptchaModal = ({ commit, state } = {}) => {
  // note: we don't want to start modal inception if parallel API returns 400 CaptchaRequired responses
  if (getters.hasCaptchaRequiredDeferred(state))
    return getters.captchaRequiredPromise(state);

  setVar99Dimension();
  sendGaEvent({ category: 'Captcha modal', action: 'Impression', nonInteractive: true });

  commit(m.SHOW_CAPTCHA_MODAL);
  commit(m.CREATE_CAPTCHA_REQUIRED_DEFERRED);
  return getters.captchaRequiredPromise(state);
};

/**
 * @type {(store: Store) => void}
 */
export const hideCaptchaModalAndShowYouAreDoneThankYouModal = ({ commit } = {}) => {
  commit(m.HIDE_CAPTCHA_MODAL);
  commit(m.SHOW_YOU_ARE_DONE_THANK_YOU_MODAL);
};

/**
 * @type {(store: Store) => void}
 */
export const hideYouAreDoneThankYouModal = ({ commit } = {}) => {
  commit(m.HIDE_YOU_ARE_DONE_THANK_YOU_MODAL);
  commit(m.RESOLVE_AND_RESET_CAPTCHA_REQUIRED_DEFERRED);
};

/**
 * @type {(store: Store) => void}
 */
export const showNewsletterConsentModal = createAction(
  volatileMutationTypes.SHOW_NEWSLETTER_CONSENT_MODAL
);

/**
 * @type {(store: Store) => void}
 */
export const hideNewsletterConsentModal = (store) => {
  const { commit } = store;
  commit(volatileMutationTypes.HIDE_NEWSLETTER_CONSENT_MODAL);
  commit(m.UNSET_SHOULD_SHOW_NEWSLETTER_CONSENT_MODAL);
  emitOnNextTick('user_afterNewsletterConsentModal');
};

/**
 * @type {(store: Store) => void}
 */
export const setIsPrivacyPolicyAccepted = createAction(m.SET_IS_PRIVACY_POLICY_ACCEPTED);

/**
 * @type {(store: Store) => void}
 */
export const unsetIsPrivacyPolicyAccepted = createAction(
  m.UNSET_IS_PRIVACY_POLICY_ACCEPTED
);

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const setNewsletterConsentModalVisibility = createAction(
  volatileMutationTypes.SET_NEWSLETTER_CONSENT_MODAL_VISIBILITY
);

/**
 * @param {Object} store
 * @param {Boolean} hasEmailSubscription
 */
export const setUserHasUserEmailSubscriptionFlag = createAction(
  m.SET_USER_HAS_USER_EMAIL_SUBSCRIPTION_FLAG
);

/**
 * @type {(store: Store) => void}
 */
export const setUserOriginalHasUserEmailSubscriptionFlag = createAction(
  m.SET_USER_ORIGINAL_HAS_USER_EMAIL_SUBSCRIPTION_FLAG
);

/**
 * @type {(store: Store) => void}
 */
export const setSelectedWdcMembership = createAction(m.SET_SELECTED_WDC_MEMBERSHIP);

export const login = async (store, payload = {}) => {
  const { credentials, openingSource = '' } = payload;
  const { dispatch, commit, state } = store;

  commit(m.REMOVE_LOGIN_ERRORS);
  commit(m.REMOVE_PROFILE_ERRORS);
  commit(m.INCREMENT_LOADING_COUNTER);
  const [loginError, loginResponse] = await to(UserService.login(credentials));
  commit(m.DECREMENT_LOADING_COUNTER);

  if (loginError) {
    resetLoginRedirectUrl(store);
    commit(m.ADD_LOGIN_ERRORS, {
      errors: apiErrors(loginError),
      isCaptchaRequired: loginError.data?.isCaptchaRequired,
    });
    log.warn(`Unable to login with user due to: ${loginError}`);
    return;
  }

  const action = credentials.isAgencyLogin ? 'Agency' : 'Normal user';

  sendGaEvent({
    category: GA_EVENT_LABEL_LOGIN,
    action,
    label: openingSource,
    payload: {
      eventLocation: getPagePathForAnalyticsWithoutLanguage(window.location),
    },
  });

  setLoyalCustomerFlag();
  await fetchProfile(store);

  if (getters.hasProfileError(state)) return;

  const loginData = loginResponse.data;
  const {
    wdc,
    isLatestGCCAccepted,
    isLatestGCCAcceptedWUK,
    isLatestGCCAcceptedWAD,
    isLatestGCCAcceptedWMT,
    isLatestWizzAccountPolicyAccepted,
    targetedWdcOffer,
    mobilePhoneWarningPopUpCanBeShown: shouldDisplayMobilePhoneWarningModal,
  } = loginData;
  commit(m.LOGIN, {
    isLatestGCCAccepted: [
      isLatestGCCAccepted,
      isLatestGCCAcceptedWUK,
      isLatestGCCAcceptedWAD,
      isLatestGCCAcceptedWMT,
    ].every(Boolean),
    isLatestWizzAccountPolicyAccepted,
    shouldDisplayMobilePhoneWarningModal,
    wdc,
    targetedWdcOffer,
  });

  dispatch(gat.AFTER_LOGIN);
  emitOnNextTick('user_afterLogin');
};

export const logout = async (store) => {
  // note: if logout fails it tries to call userSession/New
  const [error] = await to(UserService.logout());
  // note: here we want to proceed with the logout even if API calls are failed
  if (error) captureAndLogException(error);

  store.commit(m.LOGOUT);
  store.dispatch(gat.AFTER_LOGOUT);
};

export const autoLogin = async (store, guid) => {
  const { dispatch, commit, state } = store;
  commit(m.REMOVE_LOGIN_ERRORS);
  commit(m.REMOVE_PROFILE_ERRORS);
  commit(m.INCREMENT_LOADING_COUNTER);
  const [autoLoginErrors] = await to(UserService.autologin(guid));
  commit(m.DECREMENT_LOADING_COUNTER);

  if (autoLoginErrors) {
    log.warn(`Unable to auto login with user due to: ${autoLoginErrors}`);
    commit(m.ADD_LOGIN_ERRORS, { errors: apiErrorsOrDefault(autoLoginErrors) });
    return;
  }

  await fetchProfile(store);
  if (getters.hasProfileError(state)) return;

  commit(m.LOGIN, { wdc: false });
  dispatch(gat.AFTER_LOGIN);
  emitOnNextTick('user_afterLogin');
};

export const register = async (store, payload = {}) => {
  const { user, openingSource = '' } = payload;
  const { dispatch, commit, state } = store;

  commit(m.REMOVE_REGISTRATION_ERRORS);
  commit(m.REMOVE_PROFILE_ERRORS);
  commit(m.INCREMENT_LOADING_COUNTER);
  const [error, response] = await to(UserService.register(user));

  commit(m.DECREMENT_LOADING_COUNTER);

  if (error) {
    log.warn(`Unable to register new user due to: ${error}`);
    commit(m.ADD_REGISTRATION_ERRORS, apiErrors(error));
    return;
  }

  sendGaEvent({ category: 'Register', action: 'Normal user', label: openingSource });

  const { twoStepVerification } = response.data;
  if (twoStepVerification) {
    showRegistrationPendingModal(store);
    return;
  }

  await fetchProfile(store);
  if (getters.hasProfileError(state)) return;

  commit(m.LOGIN, {
    isLatestGCCAccepted: true,
    isLatestWizzAccountPolicyAccepted: true,
    shouldDisplayMobilePhoneWarningModal: false,
    wdc: false,
    targetedWdcOffer: null,
  });

  dispatch(gat.AFTER_LOGIN);
  emitOnNextTick('user_afterLogin');
};

export const fetchProfile = async (store) => {
  const { commit } = store;

  commit(m.REMOVE_PROFILE_ERRORS);
  commit(m.INCREMENT_LOADING_COUNTER);
  const [error, response] = await to(UserService.getProfile());
  commit(m.DECREMENT_LOADING_COUNTER);

  if (error) {
    captureException(error);
    log.warn(`Unable to get profile information for user due to: ${error}`);
    commit(m.ADD_PROFILE_ERRORS, apiErrors(error));
    return;
  }

  commit(m.UPDATE_PROFILE, response.data);
  commit(m.SET_FREE_WDC_SUCCESSFUL_SUBSCRIPTION, false);
};

export const fetchCustomerFeedback = async (store, flowType) => {
  const { commit } = store;
  commit(m.REMOVE_CUSTOMER_FEEDBACK_ERRORS);
  const [error, response] = await to(UserService.getCustomerFeedback(flowType));

  if (error) {
    log.warn(`Unable to get customer feedback information for user due to: ${error}`);
    commit(m.ADD_CUSTOMER_FEEDBACK_ERRORS, apiErrors(error));
    return;
  }

  commit(m.FETCH_CUSTOMER_FEEDBACK, response.data);
};

export const sendCustomerFeedback = async (store, payload) => {
  const { commit } = store;

  commit(m.SET_CUSTOMER_FEEDBACK, payload);
  const [error] = await to(UserService.sendCustomerFeedback(payload));

  if (error) {
    log.warn(`Unable to submit customer feedback due to: ${error}`);
    commit(m.ADD_CUSTOMER_FEEDBACK_ERRORS, apiErrors(error));
  }
};

export const updateMobilePhoneNumber = async (store, payload) => {
  const { commit } = store;

  commit(m.REMOVE_PROFILE_ERRORS);

  commit(m.INCREMENT_LOADING_COUNTER);
  const [error] = await to(UserService.updateMobilePhone(payload));
  commit(m.DECREMENT_LOADING_COUNTER);

  if (error) {
    const errors =
      error.status >= HTTP_STATUS_INTERNAL_SERVER_ERROR
        ? [DEFAULT_ERROR_LABEL]
        : apiErrors(error);
    log.warn(`Unable to update phone number for user due to: ${error}`);
    commit(m.ADD_PROFILE_ERRORS, errors);
  }
};

export const saveAndUpdateProfile = async (store, profile) => {
  const { commit } = store;

  commit(m.REMOVE_PROFILE_ERRORS);
  commit(m.INCREMENT_LOADING_COUNTER);

  const {
    gender: { value: gender },
    firstName: { value: firstName },
    lastName: { value: lastName },
    hasUserEmailSubscription,
  } = profile;

  const payload = {
    ...pick(
      [
        'email',
        'dateOfBirth',
        'nationality',
        'mobilePhone',
        'homePhone',
        'address',
        ...(profile.isPrivate ? [] : ['workPhone', 'invoiceDetails']),
      ],
      profile
    ),
    gender,
    firstName,
    lastName,
    consentAccepted: hasUserEmailSubscription,
  };

  Object.keys(payload).forEach((key) => {
    const value = payload[key];
    payload[key] = value === '' ? null : value; // 🤦‍
  });

  const [error, response] = await to(UserService.setProfile(payload));
  commit(m.DECREMENT_LOADING_COUNTER);

  if (error) {
    const errors =
      error.status >= HTTP_STATUS_INTERNAL_SERVER_ERROR
        ? [DEFAULT_ERROR_LABEL]
        : apiErrors(error);
    log.warn(`Unable to update profile information for user due to: ${error}`);
    commit(m.ADD_PROFILE_ERRORS, errors);
    return;
  }

  ['gender', 'firstName', 'lastName'].forEach((property) => {
    const value = response.data[property];
    response.data[property] = { value }; // 👏
  });

  commit(m.UPDATE_PROFILE, {
    ...response.data,
    isMobilePhoneValid: true, // 🍇
  });
};

/**
 * @type {(store: Store) => void}
 */
export const showInvalidBookingStateModal = createAction(
  m.SHOW_INVALID_BOOKING_STATE_MODAL
);

/**
 * @type {(store: Store) => void}
 */
export const hideInvalidBookingStateModal = createAction(
  m.HIDE_INVALID_BOOKING_STATE_MODAL
);

/**
 * @type {(store: Store, value: boolean) => void}
 */
export const setInvalidBookingStateModalVisibility = createAction(
  m.SET_INVALID_BOOKING_STATE_MODAL_VISIBILITY
);

export const fetchMyBookings = async ({ commit, state }, filter, type) => {
  filter = filter || {};

  const payload = {
    flightorigin: filter?.departureStation?.iata || '',
    flightdestination: filter?.arrivalStation?.iata || '',
    pnr: filter.pnr || '',
  };

  const response = await UserService.getMyBookings(payload);

  const {
    currentBookingsGrouped,
    pastBookingsGrouped,
    refundedBookingsGrouped,
    refundCompensationBookings: refundable = [],
    automaticRefundRefundCompensationBookings: automaticRefundable = [],
  } = response.data;

  const bookings = {
    current: currentBookingsGrouped || [],
    past: pastBookingsGrouped || [],
    cancelled: refundedBookingsGrouped || [],
    refundable,
    automaticRefundable,
  };

  if (isNotUndefined(type)) {
    commit(m[`UPDATE_MY_${type.toUpperCase()}_BOOKINGS`], bookings[type]);
  } else {
    commit(m.UPDATE_MY_CURRENT_BOOKINGS, bookings.current);
    commit(m.UPDATE_MY_PAST_BOOKINGS, bookings.past);
    commit(m.UPDATE_MY_CANCELLED_BOOKINGS, bookings.cancelled);
    commit(m.SET_REFUNDABLE_BOOKINGS, bookings.refundable);
    commit(m.SET_AUTOMATICALLY_REFUNDABLE_BOOKINGS, bookings.automaticRefundable);
  }

  if (isNotEmpty(filter)) {
    return;
  }

  if (isNotUndefined(type)) {
    commit(
      m[`UPDATE_${type.toUpperCase()}_BOOKINGS_COUNT_IN_TOTAL`],
      bookings[type].length
    );
  } else {
    commit(m.UPDATE_CANCELLED_BOOKINGS_COUNT_IN_TOTAL, bookings.cancelled.length);
    commit(m.UPDATE_CURRENT_BOOKINGS_COUNT_IN_TOTAL, bookings.current.length);
    commit(m.UPDATE_PAST_BOOKINGS_COUNT_IN_TOTAL, bookings.past.length);
  }
};

export const updateWdcAndFetchProfile = (store) => {
  const { commit } = store;
  commit(m.UPDATE_WDC, true);
  fetchProfile(store);
};

export const saveAndUpdateWdc = async (store, payload) => {
  const { commit } = store;

  commit(m.REMOVE_PROFILE_ERRORS);
  commit(m.INCREMENT_LOADING_COUNTER);

  const [error] = await to(UserService.setFreeWDC(payload));

  if (error) {
    const errors =
      error.status >= HTTP_STATUS_INTERNAL_SERVER_ERROR
        ? [DEFAULT_ERROR_LABEL]
        : apiErrors(error);
    log.warn(`Unable to update free WDC information for user do to: ${error}`);
    commit(m.ADD_PROFILE_ERRORS, errors);
    return;
  }

  await fetchProfile(store);
  commit(m.SET_FREE_WDC_SUCCESSFUL_SUBSCRIPTION, true);
  commit(m.DECREMENT_LOADING_COUNTER);
};

export const setIsFakeGeoLocationAdded = createAction(m.SET_IS_FAKE_GEO_LOCATION_ADDED);

export const setUserGeoLocation = createAction(m.SET_GEO_LOCATION);

export const setDetectedBrowserLocation = createAction(m.SET_DETECTED_BROWSING_LOCATION);

export const fetchAccountHistory = async (store) => {
  const { commit } = store;

  const [error, response] = await to(UserService.getAccountHistory());
  if (error) {
    captureException(error);
    return;
  }

  commit(m.SET_ACCOUNT_HISTORY, response.data);
};

export const setIsYourClaimsPopupClicked = createAction(
  m.SET_IS_YOUR_CLAIMS_POPUP_CLICKED
);

export const resetPreviousFirstName = createAction(m.RESET_PREVIOUS_FIRST_NAME);
export const updatePreviousFirstName = createAction(m.UPDATE_PREVIOUS_FIRST_NAME);

export const setEmailVerificationStatus = createAction(m.SET_EMAIL_VERIFICATION_STATUS);

export const showContactDataVerificationMessage = createAction(
  m.SHOW_CONTACT_DATA_VERIFICATION_MESSAGE
);
export const hideContactDataVerificationMessage = createAction(
  m.HIDE_CONTACT_DATA_VERIFICATION_MESSAGE
);
