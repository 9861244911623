import axios from 'axios';
import isUndefined from '~/utils/object/is-undefined';
import { http } from '~/utils/cache';
import { getCountryCodeByIata } from '~/utils/resource';
import toLowerCase from '~/utils/fp/to-lower-case';
import {
  ASSET_ANALYTICS,
  ASSET_BREAKING_NEWS,
  ASSET_BUNDLES,
  ASSET_CHECK_MOBILE_BOARDING_PASS_FOR_STATION,
  ASSET_COUNTRY,
  ASSET_CULTURE,
  ASSET_CULTURES,
  ASSET_CURRENCIES,
  ASSET_DISRUPTEDFLIGHTS,
  ASSET_DISRUPTION_CREDIT_INFORMATION,
  ASSET_EMERGENCY_NEWS,
  ASSET_FARECHART,
  ASSET_FEATURE_TOGGLING,
  ASSET_INIT_FEATURE_TOGGLING,
  ASSET_FEEPRICE,
  ASSET_FLIGHTINFORMATION,
  ASSET_IMPORTANT_INFO,
  ASSET_INSURANCE,
  ASSET_LOGGING_TOOLS,
  ASSET_MAP,
  ASSET_PRIVILEGE_PASS,
  ASSET_SERVICE_FEES,
  ASSET_TIMEOUT,
  ASSET_WDC_PRICE,
  ASSET_YELLOW_RIBBON,
  ASSET_CALL_CENTER_INFORMATION,
  BOOKING_SUMMARY,
  INFORMATION_BROWSER_SUPPORT,
  CHEAP_FLIGHTS_DATA_SOURCE,
  EXPEDIA_LOCATION_SEARCH_BY_IATA,
} from '~/constants/api-endpoint';

/**
 * @type {(languageCode: string) => Promise<Object<string, any>[]>}
 */
export const getCountries = (languageCode) =>
  axios.get(ASSET_COUNTRY, { params: { languageCode } });

/**
 * @type {(languageCode: string) => Promise<Object<string, any>[]>}
 */
export const getStations = (languageCode) =>
  axios.get(ASSET_MAP, { params: { languageCode } });

export const getCurrencies = (params) => axios.get(ASSET_CURRENCIES, { params });

export const getAvailableLocales = () => axios.get(ASSET_CULTURES);

export const getInsuranceByLocales = () => axios.get(ASSET_INSURANCE);

export const uploadLanguageCode = (params) => axios.post(ASSET_CULTURE, params);

export const checkMobileBoardingCardAvailability = (params) =>
  axios.post(ASSET_CHECK_MOBILE_BOARDING_PASS_FOR_STATION, params);

/**
 * Change a value in custom currency
 */
export const exchange = (amount, currencyCode) => {
  if (!isUndefined(amount)) {
    amount = Number(amount.toFixed(2));
  }

  // BE gives back 'InvalidAmount' error code if amount === 0
  // and it makes no sense to call the endpoint unnecessarily
  if (amount === 0) {
    return Promise.resolve({
      data: {
        totalAmount: {
          amount,
          currencyCode,
        },
      },
    });
  }

  const data = {
    amount,
    currencyCode,
  };
  return http.post(BOOKING_SUMMARY, data);
};

export const getPrivilegePass = (currencyCode) => {
  if (currencyCode) {
    return axios.get(ASSET_PRIVILEGE_PASS, { params: { currencyCode } });
  }

  return axios.get(ASSET_PRIVILEGE_PASS);
};

export const fareChart = (data) => axios.post(ASSET_FARECHART, data);

export const feePrice = (data) => axios.post(ASSET_FEEPRICE, data);

export const serviceFees = (data) => axios.post(ASSET_SERVICE_FEES, data);

export const flightInformation = (data) => axios.post(ASSET_FLIGHTINFORMATION, data);

export const importantInfo = (data) => axios.post(ASSET_IMPORTANT_INFO, data);

export const yellowRibbon = (languageCode) =>
  axios.get(ASSET_YELLOW_RIBBON, { params: { languageCode } });

export const callCenterInformation = () => axios.get(ASSET_CALL_CENTER_INFORMATION);

export const breakingNews = () => axios.get(ASSET_BREAKING_NEWS);

export const disruptedFlights = () => axios.get(ASSET_DISRUPTEDFLIGHTS);

export const analytics = () => axios.get(ASSET_ANALYTICS);

export const getEmergencyNews = () => axios.get(ASSET_EMERGENCY_NEWS);

export const browserSupport = () => axios.get(INFORMATION_BROWSER_SUPPORT);

export const timeOut = () => axios.get(ASSET_TIMEOUT);

export const getBundles = (data) => axios.get(ASSET_BUNDLES, data);
//
/**
 * @type (data: { languageCode?: string, testId?: string }) => Promise<Object<string, any>}
 */
export const featureToggling = ({ languageCode, testId } = {}) =>
  axios.post(
    ASSET_FEATURE_TOGGLING,
    { languageCode },
    testId ? { params: { testId } } : null
  );

export const initFeatureToggling = ({ configCatId, eTag }) =>
  axios.post(ASSET_INIT_FEATURE_TOGGLING, {
    ConfigCatId: configCatId,
    ETag: eTag,
  });

export const isInsuranceAvailableInLocale = async (
  stations,
  cultureCode,
  outboundDepartureStation
) => {
  const response = await getInsuranceByLocales();

  const departureCountryCode = getCountryCodeByIata(stations, outboundDepartureStation);
  const languageCodesByCountryCode = response.data.languageList.find(
    (country) => country.countryCode === departureCountryCode
  ) || {
    languageCode: '',
  };
  return isCultureCodeIncluded(languageCodesByCountryCode.languageCode, cultureCode);
};

const isCultureCodeIncluded = (languageCodes, cultureCode) => {
  const languages = languageCodes.split(',');
  return languages.map(toLowerCase).some((language) => cultureCode.includes(language));
};

export const getWdcPrices = () => axios.get(ASSET_WDC_PRICE);

export const getLoggingTools = () => axios.get(ASSET_LOGGING_TOOLS);

export const getDisruptionCreditInformation = () =>
  axios.get(ASSET_DISRUPTION_CREDIT_INFORMATION);

export const getCheapFlights = (data) => axios.post(CHEAP_FLIGHTS_DATA_SOURCE, data);

/**
 * @type {(iata: string) => Promise<Object<string>>}
 */
export const getExpediaLocation = (iata) =>
  axios.post(EXPEDIA_LOCATION_SEARCH_BY_IATA, { iata });
