import { ACCESSIBILITY_ENABLED } from '~/constants';
import isEmpty from '~/utils/object/is-empty';
import hassocPath from '~/utils/object/hassoc-path';
import union from '~/utils/fp/union';
import { getShortName, isFakeStation } from '~/utils/resource';
import deepClone from '~/utils/object/deep-clone';
import { mutationAssocPath } from '~/utils/store';
import {
  getResults,
  getDefaultStateOfPassengers,
  getStation,
  getTomorrowFormatted,
} from './internal';
import * as m from './mutation-types';

const reset = (state) => {
  partialReset(state);
  state.departureStation = getStation();
  state.arrivalStation = getStation();
  state.departureStationAlternatives = [];
  state.arrivalStationAlternatives = [];
  state.departureDate = getTomorrowFormatted();
  state.returnDate = '';
  state.passengers = getDefaultStateOfPassengers();
  state.rescueFareCode = '';
};

const partialReset = (state) => {
  state.wdc = false;
  state.isDomestic = false;
  state.isLoaded = false;
  state.outbound = getResults();
  state.return = getResults();
  state.hasReachedLastDateWithFlight = false;
  state.possibleDatesForFlight = {
    outbound: [],
    return: [],
  };
};

export default {
  [m.RESET]: partialReset,
  [m.RESET_SEARCH]: reset,

  /**
   * @type {(state: State, stations: Object<string, any>[]) => void}
   */
  [m.INITIALIZE]: (state, stations) => {
    // note: possible language change might wiped out the lang-specific station names,
    //  let's restore them
    //
    //  app/scripts/components/language-switcher/script.js :: changeLanguage
    //    search.departureStation.suggestion = ''
    //    search.arrivalStation.suggestion = ''

    ['departureStation', 'arrivalStation'].forEach((station) => {
      if (state[station].iata && !state[station].suggestion) {
        state[station].suggestion = getShortName(stations, state[station].iata);
      }
    });
  },

  /**
   * @type {(state: State) => void}
   */
  [m.SET_WDC]: hassocPath('wdc', true),

  /**
   * @type {(state: State) => void}
   */
  [m.UNSET_WDC]: hassocPath('wdc', false),

  /**
   * @type {(state: State, value: boolean) => void}
   */
  [m.SET_WDC_FLAG]: mutationAssocPath('wdc'),

  [m.INIT_WDC](state, payload = {}) {
    const { wdcMemberships } = payload;
    Object.assign(state, {
      wdcMemberships,
    });
  },

  [m.UPDATE_POSSIBLE_DATES_FOR_FLIGHT](state, { value, direction } = {}) {
    if (isEmpty(value)) {
      state.hasReachedLastDateWithFlight = true;
    } else {
      state.possibleDatesForFlight[direction] = value;
    }
  },

  [m.RESET_POSSIBLE_DATES_FOR_FLIGHT](state, direction) {
    hassocPath(`possibleDatesForFlight.${direction}`, [], state);
  },

  [m.SET_DESTINATION](state, { stations, iata, accessibility = ACCESSIBILITY_ENABLED }) {
    const shortName = getShortName(stations, iata);
    state.destination = {
      iata,
      shortName,
      suggestion: shortName,
      accessibility,
      isFakeStation: isFakeStation(stations, iata),
    };
  },

  [m.SET_BOOKING_DESTINATION](state, value) {
    state.bookingDestination = value;
  },

  [m.SET_CHECKIN_DATE](state, value) {
    state.checkinDate = value;
  },

  [m.SET_CHECKOUT_DATE](state, value) {
    state.checkoutDate = value;
  },

  [m.SET_DEPARTURE_DATE](state, value) {
    state.departureDate = value;
  },

  [m.SET_ORIGINAL_DEPARTURE_DATE](state, value) {
    state.originalDepartureDate = value;
  },

  [m.SET_RETURN_DATE](state, value) {
    state.returnDate = value;
  },

  [m.SET_FEES](state, payload = {}) {
    const { direction, fees } = payload;
    state[direction].fees = fees;
  },

  [m.SET_IS_BUNDLE_COMPOSITION_WARNING_TEXT_VISIBLE](state, value) {
    state.isBundleCompositionWarningTextVisible = value;
  },

  [m.SET_IS_DOMESTIC](state, value) {
    state.isDomestic = value;
  },

  [m.SET_BOOKING_CURRENCY_RENEWAL_PRICE](state, value) {
    state.bookingCurrencyRenewalPrice = value;
  },

  [m.SET_FLIGHTS](state, payload = {}) {
    const { direction, flights } = payload;
    state[direction].flights = flights;
  },

  [m.SET_BUNDLES](state, payload = {}) {
    const { direction, bundles } = payload;
    state[direction].bundles = bundles;
  },

  [m.SET_SAVED_SEARCH_PARAMS](state) {
    const { departureStation, arrivalStation, departureDate, returnDate, passengers } =
      state;

    state.savedSearchParams = deepClone({
      departureStation,
      arrivalStation,
      departureDate,
      returnDate,
      passengers,
    });
  },

  [m.REINSTATE_SAVED_SEARCH_PARAMS](state) {
    const savedParams = state.savedSearchParams;

    Object.keys(savedParams).forEach((key) => {
      state[key] = savedParams[key];
    });
  },

  [m.SET_DEPARTURE_STATION](
    state,
    { stations, iata, accessibility = ACCESSIBILITY_ENABLED }
  ) {
    const shortName = getShortName(stations, iata);
    state.departureStation = {
      iata,
      shortName,
      suggestion: shortName,
      accessibility,
      isFakeStation: isFakeStation(stations, iata),
    };
  },

  [m.SET_ARRIVAL_STATION](
    state,
    { stations, iata, accessibility = ACCESSIBILITY_ENABLED }
  ) {
    const shortName = getShortName(stations, iata);
    state.arrivalStation = {
      iata,
      shortName,
      suggestion: shortName,
      accessibility,
      isFakeStation: isFakeStation(stations, iata),
    };
  },

  [m.RESET_DEPARTURE_STATION](state) {
    state.departureStation = {
      iata: null,
      shortName: '',
      suggestion: '',
      isFakeStation: false,
    };
  },

  [m.RESET_ARRIVAL_STATION](state) {
    state.arrivalStation = {
      iata: null,
      shortName: '',
      suggestion: '',
      isFakeStation: false,
    };
  },

  [m.SET_FOCUSED_FIELD](state, station) {
    state.focusedField = station;
  },

  [m.SET_STATION_SEARCH_TERM](state, value) {
    state.stationSearchTerm = value;
  },

  [m.SET_FLIGHT_DATES](state, value) {
    state.flightDates = value;
  },

  [m.UPDATE_FLIGHT_DATES](state, value) {
    state.flightDates = union(state.flightDates, value).sort();
  },

  [m.SET_PASSENGERS](state, value) {
    state.passengers = value;
  },

  [m.SET_NUMBER_OF_ADULT_PASSENGERS](state, value) {
    state.passengers.adult = value;
  },

  [m.SET_NUMBER_OF_CHILDREN_PASSENGERS](state, value) {
    state.passengers.child = value;
  },

  [m.SET_NUMBER_OF_INFANT_PASSENGERS](state, value) {
    state.passengers.infant = value;
  },

  [m.SET_FLIGHT_DATES_SEARCH_ERRORS]: mutationAssocPath('flightDatesSearchErrors'),

  [m.RESET_SEARCH_SUGGESTIONS](state) {
    state.departureStation.suggestion = '';
    state.arrivalStation.suggestion = '';
  },

  [m.SET_DEPARTURE_STATION_SUGGESTION](state, value) {
    state.departureStation.suggestion = value;
  },

  [m.SET_ARRIVAL_STATION_SUGGESTION](state, value) {
    state.arrivalStation.suggestion = value;
  },

  [m.SET_RESCUE_FARE_CODE](state, value) {
    state.rescueFareCode = value;
  },

  [m.SET_DEPARTURE_STATION_ALTERNATIVES](state, value) {
    state.departureStationAlternatives = value;
  },

  [m.SET_ARRIVAL_STATION_ALTERNATIVES](state, value) {
    state.arrivalStationAlternatives = value;
  },

  [m.SET_SEARCH_WDC](state, value) {
    state.wdc = value;
  },

  [m.SET_IS_TIMETABLE](state, value) {
    state.isTimetable = value;
  },

  [m.SET_ACTIVE_FILTER](state, value) {
    state.activeFilter = value;
  },
};
