import { BOOKING_URL } from '~/router/booking/constants';
import compose from '~/utils/fp/compose';
import { addDays, addMonths, currentDateAndTime, toDefaultFormat } from '~/utils/date';
import { FILTER_ALL_OFFERS } from '~/components/fare-finder/constants';

export const BOOKING_SEARCH_BASE_URL = `${BOOKING_URL}/select-flight/`;
export const BOOKING_DOT_COM_SEARCH_BASE_URL =
  'https://sp.booking.com/searchresults.html';

export const getDefaultState = () => {
  return Object.assign(
    {
      passengers: getDefaultStateOfPassengers(),
      wdcMemberships: null,
      isBundleCompositionWarningTextVisible: false,
      isDomestic: false,
      isLoaded: false,
      wdc: false,
      outbound: getResults(),
      return: getResults(),
      possibleDatesForFlight: {
        outbound: [],
        return: [],
      },
      hasReachedLastDateWithFlight: false,
      departureStationAlternatives: [],
      arrivalStationAlternatives: [],
      bookingCurrencyRenewalPrice: null, // wdcRenewalPrice
      savedSearchParams: {
        departureStation: getStation(),
        arrivalStation: getStation(),
        departureDate: getTomorrowFormatted(),
        returnDate: '',
        passengers: getDefaultStateOfPassengers(),
      },
      destination: getStation(),
      checkinDate: toDefaultFormat(currentDateAndTime()),
      checkoutDate: compose(toDefaultFormat, addDays(1))(currentDateAndTime()),

      // New flight search (used on flight change)
      stationSearchTerm: '',
      focusedField: '',
      flightDates: [],
      flightDatesSearchErrors: [],
      searchMinDate: getTomorrowFormatted(),
      searchMaxDate: compose(toDefaultFormat, addMonths(2))(currentDateAndTime()),

      // Fare Finder / Smart Search
      isTimetable: false,
      activeFilter: FILTER_ALL_OFFERS,
    },

    getMinimalSearch()
  );
};

// search store fragment factory
export const getMinimalSearch = (mixin) => {
  return Object.assign(
    {
      departureStation: getStation(),
      arrivalStation: getStation(),
      departureDate: getTomorrowFormatted(),
      originalDepartureDate: getTomorrowFormatted(),
      returnDate: '',
      rescueFareCode: '',
    },
    mixin
  );
};

export const getStation = () => ({
  suggestion: '',
  shortName: '',
  iata: null,
  isFakeStation: false,
});

export const getTomorrowFormatted = () =>
  compose(toDefaultFormat, addDays(1))(currentDateAndTime());

export const getDefaultStateOfPassengers = () => ({
  adult: 1,
  child: 0,
  infant: 0,
});

export const getResults = () => ({
  flights: [], // search results
  bundles: [], // search results
  fees: {
    travelPartner: null,
  },
});
