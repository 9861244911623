import type { IConfigCatCache } from 'configcat-js';

export class CustomConfigCatCache implements IConfigCatCache {
  set(key: string, value: string): Promise<void> | void {
    localStorage.setItem(key, toUtf8Base64(value));

    const [timeStamp, eTagRaw] = value.split('\n');
    const eTag = eTagRaw.match(/W\/"(.*)"/)[1];
    localStorage.setItem(
      'configcat-meta',
      JSON.stringify({
        timeStamp,
        eTag,
      })
    );
  }

  get(key: string): Promise<string | null | undefined> | string | null | undefined {
    const cacheFromLocalStorage = localStorage.getItem(key);

    if (cacheFromLocalStorage) {
      return fromUtf8Base64(cacheFromLocalStorage);
    }
  }
}

// copied from configcat cache implementation: https://github.com/configcat/js-sdk/blob/master/src/Cache.ts
const toUtf8Base64 = (value: string): string => {
  value = encodeURIComponent(value);
  value = value.replace(/%([\dA-F]{2})/g, (_, p1) =>
    String.fromCharCode(Number.parseInt(p1, 16))
  );
  return btoa(value);
};

const fromUtf8Base64 = (value: string): string => {
  value = atob(value);
  value = value.replace(/[%\x80-\xFF]/g, (m) => `%${m.charCodeAt(0).toString(16)}`);
  return decodeURIComponent(value);
};
