import {
  OUTBOUND_FLIGHT_NAME,
  RETURN_FLIGHT_NAME,
  OUTBOUND_FLIGHT,
  RETURN_FLIGHT,
  BAGGAGE_LIGHT,
  BAGGAGE_EXTRA_LIGHT,
  BAGGAGE_ULTRA_LIGHT,
  BAGGAGE_HEAVY,
  BAGGAGE_MEDIUM,
  BUNDLE_MIDDLE,
} from '~/constants';
import {
  SPECIFIC_TYPE_INVOICE_CHANGE,
  SPECIFIC_TYPE_TRAVEL_INSURANCE,
  SPECIFIC_TYPE_WIZZ_FLEX,
  SPECIFIC_TYPE_PRIORITY_BOARDING,
} from '~/constants/specific-type';
import __ from '~/utils/fp/__';
import curry from '~/utils/fp/curry';
import filter from '~/utils/fp/filter';
import path from '~/utils/fp/path';
import compose from '~/utils/fp/compose';
import length from '~/utils/fp/length';
import propEq from '~/utils/fp/prop-eq';
import unnest from '~/utils/fp/unnest';
import gt from '~/utils/fp/gt';
import props from '~/utils/fp/props';
import orElse from '~/utils/fp/or-else';
import prop from '~/utils/fp/prop';
import omit from '~/utils/fp/omit';
import evolve from '~/utils/fp/evolve';
import map from '~/utils/fp/map';
import entries from '~/utils/fp/entries';
import reduce from '~/utils/fp/reduce';
import has from '~/utils/fp/has';
import add from '~/utils/fp/add';
import pluck from '~/utils/fp/pluck';
import reject from '~/utils/fp/reject';
import isNil from '~/utils/object/is-nil';
import chain from '~/utils/fp/chain';
import and from '~/utils/fp/and';
import some from '~/utils/fp/some';
import equals from '~/utils/fp/equals';
import ifElse from '~/utils/fp/if-else';
import find from '~/utils/fp/find';
import isNotEmpty from '~/utils/object/is-not-empty';
import { getFlightTitle } from '~/utils/resource';
import {
  getPlaceHolderName,
  isFemale,
  isMale,
  isAdult,
  isChild,
} from '~/utils/booking/passenger';
import anyPass from '~/utils/fp/any-pass';
import sortByProp from '~/utils/fp/sort-by-prop';
import values from '~/utils/fp/values';
import isEmpty from '~/utils/object/is-empty';
import { getDefaultPrice } from '~/utils/price';
import sort from '~/utils/fp/sort';
import pathOr from '~/utils/fp/path-or';
import * as coreBookingGetters from '../core-booking/getters';
import * as passengersGetters from '../passengers/getters';
import * as resourcesGetters from '../resources/getters';
import { calculateDiscountPrice, isPaymentStatusAccepted } from './internal';

const summaryProp = (propName) => path(`summary.${propName}`);

/**
 * @type {(state: State) => string|null}
 */
export const mcpSelectionSource = summaryProp('mcpSelectionSource');

/**
 * @type {(state: State) => Object<string, any>}
 */
export const isWizzFlexSelectedAtFlightSelect = summaryProp(
  'isWizzFlexSelectedAtFlightSelect'
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const pastPurchases = summaryProp('pastPurchases');

/**
 * @type {(state: State) => Object<string, any>}
 */
const pastPurchasesFareLockFinalize = compose(prop('fareLockFinalize'), pastPurchases);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const fareLockFinalizeLockedAtPrice = compose(
  prop('lockedAtPrice'),
  pastPurchasesFareLockFinalize
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const fareLockFinalizeTotal = compose(
  prop('total'),
  pastPurchasesFareLockFinalize
);

/**
 * @type {(sectionGetter: (state: State) => Object<string, any>, state: State) => Object<string, any>}
 */
const sectionTotal = curry(
  (sectionGetter, state) =>
    sectionGetter(state).total || getDefaultAmountInBookingCurrency(state)
);

/**
 * @type {(state: State) => Object<string, any>}
 */
const paymentsSection = summaryProp('payments');

/**
 * @type {(state: State) => []}
 */
export const coupons = compose(prop('coupons'), paymentsSection);

/**
 * @type {(state: State) => []}
 */
export const payments = compose(prop('payments'), paymentsSection);

/**
 * @type {(state: State) => []}
 */
export const acceptedPayments = compose(filter(isPaymentStatusAccepted), payments);

/**
 * @type {(state: State) => boolean}
 */
export const isPartialPayment = compose(isNotEmpty, acceptedPayments);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const paymentsTotal = sectionTotal(paymentsSection);

const loadingCounter = summaryProp('loadingCounter');

/**
 * @type {(state: State) => boolean}
 */
export const isLoading = compose(gt(__, 0), loadingCounter);

/**
 * @type {(state: State) => boolean}
 */
export const hasThirdPartyServices = (state) => {
  const { amount } = amountDue(state);
  const { amount: amountWithout } = amountDueWithoutThirdPartyServices(state);
  return amount > amountWithout;
};

/**
 * @type {(state: State) => Object<string, any>}
 */
export const total = (state) => _total(state) || getDefaultAmountInBookingCurrency(state);

/**
 * @type {(state: State) => Object<string, any>}
 */
const _total = summaryProp('total');

/**
 * @type {(state: State) => Object<string, any>}
 */
export const amountDue = (state) =>
  _amountDue(state) || getDefaultAmountInBookingCurrency(state);

/**
 * @type {(state: State) => Object<string, any>}
 */
const _amountDue = summaryProp('amountDue');

/**
 * @type {(state: State) => boolean}
 */
export const isEmptyPayment = compose(equals(0), prop('amount'), amountDue);

/**
 * @type {(state: State) => boolean}
 */
export const hasAmountDue = compose(gt(__, 0), prop('amount'), amountDue);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const exchangedAmountDue = (state) =>
  _exchangedAmountDue(state) || getDefaultAmountInArrivalCurrency(state);

/**
 * @type {(state: State) => Object<string, any>}
 */
const _exchangedAmountDue = summaryProp('exchangedAmountDue');

/**
 * @type {(state: State) => Object<string, any>}
 */
export const amountDueWithoutThirdPartyServices = (state) =>
  _amountDueWithoutThirdPartyServices(state) || getDefaultAmountInBookingCurrency(state);

/**
 * @type {(state: State) => Object<string, any>}
 */
const _amountDueWithoutThirdPartyServices = summaryProp(
  'amountDueWithoutThirdPartyServices'
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const exchangedAmountDueWithoutThirdPartyServices = (state) =>
  _exchangedAmountDueWithoutThirdPartyServices(state) ||
  getDefaultAmountInArrivalCurrency(state);

/**
 * @type {(state: State) => Object<string, any>}
 */
const _exchangedAmountDueWithoutThirdPartyServices = summaryProp(
  'exchangedAmountDueWithoutThirdPartyServices'
);

/**
 * @type {(state: State) => Boolean}
 */
const isPaymentCurrencyChangedFromBookingCurrency =
  coreBookingGetters.isPaymentCurrencyChangedFromBookingCurrency;

/**
 * @type {(state: State) => Object<string, any>}
 */
export const currentAmountDue = ifElse(
  isPaymentCurrencyChangedFromBookingCurrency,
  exchangedAmountDue,
  amountDue
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const currentAmountDueWithoutThirdPartyServices = ifElse(
  isPaymentCurrencyChangedFromBookingCurrency,
  exchangedAmountDueWithoutThirdPartyServices,
  amountDueWithoutThirdPartyServices
);

/**
 * @type {(state: State) => Object<string, any>}
 */
const servicesSection = summaryProp('services');

/**
 * @type {(state: State) => []}
 */
export const flatServices = compose(
  sortByProp('order'),
  values,
  reduce(
    (acc, service) => {
      if (!has(service.label, acc)) {
        acc[service.label] = service;
      } else {
        acc[service.label] = evolve(
          {
            price: (price) => ({ ...price, amount: price.amount + service.price.amount }),
            displayCount: add(service.displayCount),
          },
          acc[service.label]
        );
      }
      return acc;
    },
    () => ({})
  ),
  unnest,
  props(['globals', 'outboundFlight', 'returnFlight']),
  prop('services'),
  servicesSection
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const groupedServices = compose(prop('services'), servicesSection);

/**
 * @type {(state: State) => []}
 */
const globalServices = compose(prop('globals'), groupedServices);

/**
 * @type {(state: State) => boolean}
 */
export const areAnyServiceSelected = compose(isNotEmpty, flatServices);

/**
 * @type {(state: State) => boolean}
 */
export const areAnyPayableServiceSelected = compose(
  some(gt(__, 0)),
  map(path('price.amount')),
  flatServices
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const servicesTotal = sectionTotal(servicesSection);

export const seatsPassengers = (state) => {
  const { passengers: passengersWithSeat } = seatsSection(state);
  return passengersWithSeat.map((passengerWithSeat) => {
    const passenger = passengers(state).find(propEq('id', passengerWithSeat.id));
    const { outboundSeat = {}, returnSeat = {} } = passengerWithSeat;
    return {
      ...passenger,
      outboundSeat,
      returnSeat,
    };
  });
};

export const seatsPassengersListItems = (state) => {
  const { passengers: passengersWithSeat } = seatsSection(state);
  return passengersWithSeat.map((passengerWithSeat) => {
    const passenger = passengers(state).find(propEq('id', passengerWithSeat.id)) || {};
    const { outboundSeat = {}, returnSeat = {} } = passengerWithSeat;
    return {
      ...passenger,
      outboundSeat: createSeatsPassengersListItems(outboundSeat),
      returnSeat: createSeatsPassengersListItems(returnSeat),
    };
  });
};

const createSeatsPassengersListItems = (seat) => {
  if (isEmpty(seat)) return {};

  const result = { ...seat, discount: false };
  if (seat.promotedPrice) {
    result.discount = true;
    result.discountPrice = {
      amount: calculateDiscountPrice(seat.promotedPrice.amount, seat.price.amount),
      currencyCode: seat.price.currencyCode,
    };
  }

  return result;
};

/**
 * @type {(state: State) => Object<string, any>}
 */
const seatsSection = summaryProp('seats');

const seatsPassengersRaw = compose(prop('passengers'), seatsSection);

/**
 * @type {(state: State) => boolean}
 */
const areAnySeatSelected = compose(isNotEmpty, seatsPassengersRaw);

export const areAnySeatSelectedOnEitherFlight = compose(
  some((p) => isNotEmpty(p.outboundSeat) || isNotEmpty(p.returnSeat)),
  prop('passengers'),
  seatsSection
);

export const areAnyPayableSeatSelectedOnEitherFlight = compose(
  some(
    (p) =>
      gt(path('outboundSeat.price.amount', p), 0) ||
      gt(path('returnSeat.price.amount', p), 0)
  ),
  prop('passengers'),
  seatsSection
);
/**
 * @type {(state: State) => Object<string, any>}
 */
export const seatsTotal = sectionTotal(seatsSection);

/**
 * @type {(state: State) => Object<string, any>}
 */
const passengersSection = summaryProp('passengers');

/**
 * @type {(state: State) => []}
 */
export const passengersAncillaries = compose(prop('ancillaries'), passengersSection);

/**
 * @type {(state: State) => boolean}
 */
const areAnyAncillarySelected = compose(isNotEmpty, passengersAncillaries);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const hasPurchasedInsuranceAlready = (state) =>
  summaryProp('hasPurchasedInsuranceAlready')(state);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const passengersTotal = sectionTotal(passengersSection);

export const passengersPassengers = (state) => {
  const { ancillaries } = passengersSection(state);
  return passengers(state).map((passenger) => {
    const ancillary = omit(
      ['passengerId'],
      ancillaries.find(propEq('passengerId', passenger.id)) || {}
    );
    const newAncillary = groupPassengerAncillary(ancillary);
    return {
      ...passenger,
      ancillary: isNotEmpty(newAncillary)
        ? newAncillary
        : { generic: [], [OUTBOUND_FLIGHT]: [], [RETURN_FLIGHT]: [] },
    };
  });
};

export const passengersListItems = (state) => {
  const { ancillaries } = passengersSection(state);
  return passengers(state).map((passenger) => {
    const ancillary = omit(
      ['passengerId'],
      ancillaries.find(propEq('passengerId', passenger.id)) || {}
    );
    const newAncillary = groupPassengerAncillary(ancillary);
    const generic = createListItemFromPromotedPrice(newAncillary.generic);
    const outboundFlight = createListItemFromPromotedPrice(newAncillary[OUTBOUND_FLIGHT]);
    const returnFlight = createListItemFromPromotedPrice(newAncillary[RETURN_FLIGHT]);

    return {
      ...passenger,
      ancillary: {
        generic,
        [OUTBOUND_FLIGHT]: outboundFlight,
        [RETURN_FLIGHT]: returnFlight,
      },
    };
  });
};

const groupPassengerAncillary = (ancillary = {}) => {
  return evolve(
    {
      generic: groupAncillaries,
      [OUTBOUND_FLIGHT]: groupAncillaries,
      [RETURN_FLIGHT]: groupAncillaries,
    },
    ancillary
  );
};

const createListItemFromPromotedPrice = (ancillaries) =>
  (ancillaries || []).reduce((acc, item) => {
    acc.push({ ...item, discount: false });

    if (
      !item.included &&
      item.promotedPrice &&
      item.promotedPrice.amount < item.price.amount
    ) {
      const discountPriceAmount = calculateDiscountPrice(
        item.promotedPrice.amount,
        item.price.amount
      );
      if (discountPriceAmount !== 0) {
        acc.push({
          ...item,
          discount: true,
          discountPrice: {
            amount: discountPriceAmount,
            currencyCode: item.price.currencyCode,
          },
        });
      }
    }

    return acc;
  }, []);

/**
 * @template T
 * @type {(ancillaries: T[]) => T[]}
 */
const groupAncillaries = compose(
  map(([_, value]) => value),
  entries,
  reduce(
    (acc, ancillary) => {
      const key = ancillary.label + ancillary.included;
      if (!has(key, acc)) {
        acc[key] = ancillary;
      } else {
        acc[key] = evolve(
          {
            displayCount: add(ancillary.displayCount),
            price: (price) => ({
              ...price,
              amount: price.amount + ancillary.price.amount,
            }),
            promotedPrice: (price) =>
              price
                ? { ...price, amount: price.amount + ancillary.promotedPrice.amount }
                : price,
            included: and(ancillary.included),
          },
          acc[key]
        );
      }

      return acc;
    },
    () => ({})
  ),
  // in case of post booking upsell the fare should be positioned as the first item.
  sort((a, b) => (a.specificType && a.specificType.startsWith(BUNDLE_MIDDLE) ? -1 : 0))
);

/**
 * @type {(passenger: {}) => boolean}
 */
const isPassengerHasAncillary = compose(
  gt(__, 0),
  length,
  unnest,
  props(['generic', OUTBOUND_FLIGHT, RETURN_FLIGHT]),
  orElse({ generic: [], [OUTBOUND_FLIGHT]: [], [RETURN_FLIGHT]: [] }),
  prop('ancillary')
);

const isPassengerHasPayableAncillary = compose(
  some(gt(__, 0)),
  map(path('price.amount')),
  unnest,
  props(['generic', OUTBOUND_FLIGHT, RETURN_FLIGHT]),
  orElse({ generic: [], [OUTBOUND_FLIGHT]: [], [RETURN_FLIGHT]: [] })
);

const compactFlightAncillaries = curry((flightName, passengers) =>
  compose(
    map(([label, details]) => ({
      label,
      specificType: details.specificType,
      displayCount: details.displayCount,
      price: details.price,
      included: details.included,
      feeCode: details.feeCode,
    })),
    entries,
    reduce((acc, ancillary) => {
      if (!has(ancillary.label, acc)) {
        acc[ancillary.label] = {
          label: ancillary.label,
          displayCount: ancillary.displayCount,
          included: ancillary.included,
          price: ancillary.promotedPrice ? ancillary.promotedPrice : ancillary.price,
          feeCode: ancillary.feeCode,
        };
      } else {
        const _price = ancillary.promotedPrice
          ? ancillary.promotedPrice
          : ancillary.price;
        acc[ancillary.label] = evolve(
          {
            price: (price) => ({ ...price, amount: price.amount + _price.amount }),
            displayCount: add(ancillary.displayCount),
          },
          acc[ancillary.label]
        );
      }

      acc[ancillary.label].specificType = ancillary.specificType;

      return acc;
    }, {}),
    chain(prop(`${flightName}Flight`)),
    reject(isNil),
    pluck('ancillary')
  )(passengers)
);

/**
 * @type {(state: State) => []}
 */
export const compactOutboundAncillaries = compose(
  compactFlightAncillaries(OUTBOUND_FLIGHT_NAME),
  passengersPassengers
);

/**
 * @type {(state: State) => []}
 */
export const compactReturnAncillaries = compose(
  compactFlightAncillaries(RETURN_FLIGHT_NAME),
  passengersPassengers
);

/**
 * @type {(state: State) => boolean}
 */
export const hasCompactOutboundAncillaries = compose(
  isNotEmpty,
  compactOutboundAncillaries
);

export const groupBookingOutboundCheckedInBagPrice = (state) => {
  const baggageTypes = [
    ...BAGGAGE_LIGHT,
    ...BAGGAGE_EXTRA_LIGHT,
    ...BAGGAGE_ULTRA_LIGHT,
    ...BAGGAGE_MEDIUM,
    ...BAGGAGE_HEAVY,
  ];
  return compactOutboundAncillaries(state).find((acillary) => {
    return baggageTypes.includes(acillary.specificType);
  });
};

export const groupBookingReturnCheckedInBagPrice = (state) => {
  const baggageTypes = [
    ...BAGGAGE_LIGHT,
    ...BAGGAGE_EXTRA_LIGHT,
    ...BAGGAGE_ULTRA_LIGHT,
    ...BAGGAGE_MEDIUM,
    ...BAGGAGE_HEAVY,
  ];
  return compactReturnAncillaries(state).find((acillary) => {
    return baggageTypes.includes(acillary.specificType);
  });
};

export const groupBookingOutboundPriorityBoardingPrice = (state) =>
  compactOutboundAncillaries(state).find(
    (acillary) => acillary.specificType === SPECIFIC_TYPE_PRIORITY_BOARDING
  );

export const groupBookingReturnPriorityBoardingPrice = (state) =>
  compactReturnAncillaries(state).find(
    (acillary) => acillary.specificType === SPECIFIC_TYPE_PRIORITY_BOARDING
  );

/**
 * @type {(state: State) => boolean}
 */
export const hasCompactReturnAncillaries = compose(isNotEmpty, compactReturnAncillaries);

/**
 * @type {(state: State) => []}
 */
export const passengersPassengersWithAncillaries = compose(
  filter(isPassengerHasAncillary),
  passengersPassengers
);

export const arePassengersPassengersWithPayableAncillaries = compose(
  gt(__, 0),
  length,
  filter(isPassengerHasPayableAncillary),
  passengersAncillaries
);

export const areAnyPayableAncillaryItemSelected = anyPass(
  areAnyPayableSeatSelectedOnEitherFlight,
  areAnyPayableServiceSelected,
  arePassengersPassengersWithPayableAncillaries
);

export const passengers = (state) =>
  passengersGetters.independentPassengers(state).map(passengersFrom);

const passengersFrom = (passenger = {}, index = 0) => {
  const {
    passengerNumber: id = -1,
    firstName = '',
    lastName = '',
    gender = '',
    passengerType: type = '',
  } = passenger;
  const displayName = getPlaceHolderName(passenger, index + 1);
  const kind = getPassengerKind(passenger);
  return {
    id,
    firstName,
    lastName,
    displayName,
    gender,
    type,
    kind,
  };
};

// TODO can't we merge this with already existing ones?
const getPassengerKind = (passenger) => {
  const { gender } = passenger;
  const male = isMale(passenger);
  const female = isFemale(passenger);
  const adult = isAdult(passenger);
  const child = isChild(passenger);

  if (adult && female) return 'woman';
  if (adult && male) return 'man';
  if (adult && !gender) return 'unknown';
  if (child) return 'child';

  return 'infant';
};

export const outboundFlightTitle = (state) =>
  getFlightTitle(
    resourcesGetters.stationsWithFakes(state),
    outboundDepartureStationIata(state),
    outboundArrivalStationIata(state)
  );

export const returnFlightTitle = (state) =>
  getFlightTitle(
    resourcesGetters.stationsWithFakes(state),
    returnDepartureStationIata(state),
    returnArrivalStationIata(state)
  );

/**
 * @type {(state: State) => Object<string, any>}
 */
const flightsSection = summaryProp('flights');

/**
 * @type {(state: State) => Object<string, any>}
 */
export const payableWdcMembership = compose(prop('payableWdcMembership'), flightsSection);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const wdcMembershipFee = compose(prop('wdcMembershipFee'), flightsSection);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const wdcMembershipSpecificType = compose(
  prop('wdcMembershipSpecificType'),
  flightsSection
);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const wdcRenewalFee = compose(prop('wdcRenewalFee'), flightsSection);

/**
 * @type {(state: State) => boolean}
 */
export const hasWdcMembershipFee = compose(isNotEmpty, wdcMembershipFee);

/**
 * @type {(state: State) => boolean}
 */
export const hasWdcRenewalFee = compose(isNotEmpty, wdcRenewalFee);

/**
 * @type {(state: State) => Object<string, any>}
 */
const fareLock = compose(prop('fareLock'), flightsSection);

export const fareLockLockedPrice = (state) =>
  compose(
    orElse(getDefaultPrice(coreBookingGetters.bookingCurrencyCode(state))),
    prop('lockedPrice'),
    fareLock
  )(state);

export const fareLockPrice = (state) =>
  compose(
    orElse(getDefaultPrice(coreBookingGetters.bookingCurrencyCode(state))),
    prop('price'),
    fareLock
  )(state);

/**
 * @type {(state: State) => boolean}
 */
export const isFareLockAdded = compose(isNotEmpty, fareLock);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const flightsTotal = sectionTotal(flightsSection);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const outboundFlight = compose(prop(OUTBOUND_FLIGHT), flightsSection);

/**
 * @type {(state: State) => Object<string, any>}
 */
export const returnFlight = compose(prop(RETURN_FLIGHT), flightsSection);

/**
 * @type {(state: State) => boolean}
 */
export const isOutboundFlightSelected = compose(isNotEmpty, outboundFlight);

/**
 * @type {(state: State) => boolean}
 */
export const isReturnFlightSelected = compose(isNotEmpty, returnFlight);

/**
 * @type {(state: State) => boolean}
 */
export const hasItemsInCart = anyPass(
  isOutboundFlightSelected,
  isReturnFlightSelected,
  areAnyAncillarySelected,
  areAnySeatSelected,
  areAnyServiceSelected,
  isFareLockAdded,
  hasWdcMembershipFee,
  hasWdcRenewalFee
);

/**
 * @type {(state: State) => Object<string, any>}
 */
// NOTE: should not be used as a getter
const getDefaultAmountInBookingCurrency = compose(
  getDefaultPrice,
  coreBookingGetters.bookingCurrencyCode
);

/**
 * @type {(state: State) => string}
 */
const bookingArrivalCurrencyCode = coreBookingGetters.arrivalCurrencyCode;

/**
 * @type {(state: State) => Object<string, any>}
 */
// NOTE: should not be used as a getter
const getDefaultAmountInArrivalCurrency = compose(
  getDefaultPrice,
  bookingArrivalCurrencyCode
);

/**
 * @type {(state: State) => boolean}
 */
export const hasWizzFlex = compose(
  Boolean,
  find(propEq('specificType', SPECIFIC_TYPE_WIZZ_FLEX)),
  pathOr([], '0.outboundFlight'),
  passengersAncillaries
);

/**
 * @type {(state: State) => boolean}
 */
export const hasInvoiceChangeFee = compose(
  Boolean,
  find(propEq('specificType', SPECIFIC_TYPE_INVOICE_CHANGE)),
  globalServices
);

/**
 * @type {(state: State) => boolean}
 */
export const hasInsurance = compose(
  Boolean,
  find(propEq('specificType', SPECIFIC_TYPE_TRAVEL_INSURANCE)),
  globalServices
);

/**
 * @type {(state: State) => string}
 */
const outboundDepartureStationIata = (state) =>
  summaryProp('flights.outboundFlight.departureStationIata')(state) || '';

/**
 * @type {(state: State) => string}
 */
const outboundArrivalStationIata = (state) =>
  summaryProp('flights.outboundFlight.arrivalStationIata')(state) || '';

/**
 * @type {(state: State) => string}
 */
const returnDepartureStationIata = (state) =>
  summaryProp('flights.returnFlight.departureStationIata')(state) || '';

/**
 * @type {(state: State) => string}
 */
const returnArrivalStationIata = (state) =>
  summaryProp('flights.returnFlight.arrivalStationIata')(state) || '';

/**
 * @type {(state: State) => string}
 */
export const departureStationIata = (state) =>
  summaryProp('departureStation')(state) || '';

/**
 * @type {(state: State) => string}
 */
export const arrivalStationIata = (state) => summaryProp('arrivalStation')(state) || '';

/**
 * @type {(state: State) => string}
 */
export const outboundPayableItem = (state) =>
  summaryProp('flights.outboundPayableItem')(state) || null;

/**
 * @type {(state: State) => string}
 */
export const returnPayableItem = (state) =>
  summaryProp('flights.returnPayableItem')(state) || null;
